import React, { useEffect } from 'react'
import CommonContent from '../CommonRouter/commonContent';
import { Container, createTheme, Grid, Paper, ThemeProvider, Typography } from '@mui/material'
import MakersSlider from '../components/MakersSlider'
import styled from '@emotion/styled'



const Item = styled(Paper)(({ theme }) => ({
    // padding: theme.spacing(1),
    textAlign: 'center',
    background: 'none',
    boxShadow: 'none',
    // [theme.breakpoints.down('md')]: {
    //     backgroundColor: '#27292A'
    // }
}))




function ChildAbout() {


    return (
        <>
            <Container
                sx={{ maxWidth: { lg: '1400px' }, }}
                style={{ color: '#fff' }}
            >
                <Grid container >
                    <Grid item xs={12}>
                        <p
                            style={{
                                marginBlock: '40px',
                                color: "#242E3D",
                                // textAlign: 'center',
                                fontSize: '22px',
                                lineHeight: '36px'
                            }}
                        >
                            Georgia Filming is a production company that contributes
                            significantly to the entertainment business by creating and making
                            the content that we watch and love on a daily basis. From
                            pre-production scheduling and script writing through
                            post-production editing and distribution, Georgia Filming is in
                            charge of overseeing the full production process. We utilise the
                            most recent technology developments available in the market, stay
                            flexible in the face of shifting market dynamics, and are capable
                            of creating high-quality content that stands out from the crowd in
                            accordance with the tastes and trends of the target audience. <br /><br />At
                            our production house, we are constantly striving towards
                            revolutionizing the digital presence of our corporate clients and
                            other businesses. Our vision is to empower them with a
                            comprehensive suite of digital solutions that can elevate their
                            brand image, expand their reach, and enhance customer engagement.
                            We understand that in today's digital age, having a robust online
                            presence is crucial for any business to stay relevant and
                            competitive. Therefore, our focus is on providing customized
                            solutions that cater to the unique needs of each of our clients.<br /><br />
                            Our team of experts consists of seasoned professionals who have
                            years of experience in handling digital platforms for various
                            businesses across different industries. We bring a wealth of
                            knowledge and expertise to the table and are committed to
                            delivering exceptional results. At our production house, we place
                            a strong emphasis on quality and creativity. We believe in pushing
                            boundaries and coming up with innovative ideas that can help our
                            clients stand out from the crowd. Overall, our vision is to be
                            committed to delivering excellence and ensuring our clients'
                            satisfaction with our services. 
                        </p>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Grid xs={12}>
                        <Typography
                            variant='h2'
                            className='title2'
                            style={{ color: 'black' }}
                        >
                            OUR TEAM
                        </Typography>
                        <div
                            style={{
                                textAlign: 'center',
                                fontSize: '16px',
                                color: 'black'
                            }}
                        >
                            -----------------
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            sx={{ my: 2 }}
                            style={{
                                fontSize: '20px',
                                textAlign: 'center',
                                color: '#000'
                            }}
                        >
                            At our Georgia Filming production service, we combine our technical expertise with our creative vision to deliver high-quality content that meets your unique needs. Planning and Executing with the Team and fulfilling the clients expectations.
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ textAlign: 'right' }}
                        justifyContent='end'
                    >
                        <Item sx={{}} style={{ backgroundColor: '#fff' }}>
                            <div style={{ marginTop: '35px' }}>
                                <MakersSlider />
                            </div>
                            <br />
                        </Item>
                        <h5 style={{ textAlign: 'center', fontSize: '30px', color: 'black', marginTop: "20px" }}>
                            Emphasize technical expertise and creative vision.
                        </h5>
                        <br />
                    </Grid>
                </Grid>
                <p style={{
                    marginBottom: '60px',
                    color: "#242E3D",
                    // textAlign: 'center',
                    fontSize: '22px',
                    lineHeight: '36px'
                }}>Our team of creative experts
                    includes a writer, a cinematographer, an Editor, and a sound
                    engineer who collaborate to make a project come to life. Through
                    our collaboration with NGR Media House in INDIA, a business that
                    specializes in designing Pre & Post - Production, we are able to
                    provide social media services, website design services, content
                    videos for marketing, advertainment other services in India.</p>
            </Container >
        </>
    )
}


function About() {
    const backgroundImage = "https://georgiafilming.s3.us-east-1.amazonaws.com/1+(1).png"

    return (
        <>
            <CommonContent mainData={<ChildAbout />}
                backgroundImage={backgroundImage}
                imageContent="ABOUT US"
            />
        </>
    )
}


export default About