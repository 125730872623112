import * as React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { CustomButtonGroupAsArrows } from './CustomArrows'
import { Typography } from '@mui/material'
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
}
const images = [
  {
    msg: 'Georgia Filming Production has been a game-changer for our post-production needs. Their team took our raw footage and turned it into a polished, professional product that perfectly captured the essence of our brand. The video editing was seamless, and they were able to meet all of our tight deadlines without sacrificing quality. We couldn’t be happier with the final product!',
    name: 'Samantha Harris, CEO of Harris Innovations (USA)'
  },
  {
    msg: "We partnered with Georgia Filming Production for our upcoming seminar, and their work on our posters and printed flyers exceeded our expectations. Not only did they design stunning visuals that truly represented our brand, but they also ensured the materials were delivered on time for our event. The flyer distribution and design were both modern and engaging. We definitely plan on using them again!",
    name: ' Arjun Mehra, Founder of Mehra Consulting (India) '
  },
  {
    msg: "For our company's annual conference, we needed a professional team that could capture the essence of the event, and Georgia Filming Production delivered beyond expectations. Their corporate event shooting team was on point, capturing every important moment with precision. The team was unobtrusive, yet they managed to film the entire event from different angles without missing a beat. The footage was not only high-quality but also creatively shot, which allowed us to showcase the event in the best possible light.",
    name: 'Jonathan Blake, Marketing Director at AlphaTech Solutions (USA)'
  },
  {
    msg: 'We approached Georgia Filming Production to create a series of promotional videos for our new product line, and the result was nothing short of spectacular. From concept development to final edits, they understood our vision and delivered high-quality videos that were not only visually stunning but also strategically effective. Their team made sure the videos resonated with our target audience, helping us boost both online engagement and sales.',
    name: 'Priya Kapoor, Marketing Head at VisionTech Pvt. Ltd. (India)'
  },
  {
    msg: `Georgia Filming Production’s expertise in video editing and post-production has
been invaluable for our creative agency. We've collaborated on several projects, and their team consistently brings fresh ideas, flawless editing, and exceptional attention to detail. Their post-production team seamlessly integrated various elements, from sound design to color grading, resulting in visually striking and compelling videos. They're a trusted partner for our ongoing video projects.`,
    name: " Michael O'Neil, Creative Director at O'Neil Media Group (USA)"
  },
  {
    msg: `We enlisted Georgia Filming Production for our corporate event, and they handled everything from filming the entire program to designing our event flyers. The quality of the video was top-notch, capturing both the key moments and the spirit of the event. The flyers they designed were perfect for promoting the event beforehand and helped us attract a larger audience. Their professionalism and creativity made all the difference, and we look forward to future collaborations!`,
    name: "Rajesh Reddy, CEO of Reddy Enterprises (India)"
  }
]
export default function TestimonialsContent() {
  return (
    <div>
      <Carousel
  additionalTransfrom={0}
  arrows={false}
  autoPlay
  autoPlaySpeed={4000}
  centerMode={false}
  className=""
  containerClass="container-padding-bottom"
  customButtonGroup={<CustomButtonGroupAsArrows />}
  dotListClass=""
  draggable
  focusOnSelect={false}
  infinite
  itemClass=""
  keyBoardControl
  minimumTouchDrag={80}
  pauseOnHover
  renderArrowsWhenDisabled={false}
  renderButtonGroupOutside
  renderDotsOutside={false}
  responsive={{
    desktop: {
      breakpoint: {
        max: 4000,
        min: 1024,
      },
      items: 1,
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 1,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 30,
    },
  }}
  rewind={false}
  rewindWithAnimation={false}
  rtl={false}
  shouldResetAutoplay
  showDots={false}
  sliderClass=""
  slidesToSlide={1}
  swipeable
>
  {images.slice(0, 7).map((image, index) => (
    <div
      key={index}
      style={{
        margin: "0px 12px",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          my: 2,
          textAlign: "center",
          fontSize: { xs: "16px", sm: "18px", md: "20px" }, // Responsive font sizes
          lineHeight: { xs: "20px", sm: "24px", md: "28px" },
        }}
      >
        {image?.msg}
      </Typography>
      <p
        style={{
          textAlign: "center",
          fontSize: "14px",
          fontWeight: "400",
        }}
      >
        {image?.name}
        <hr
          style={{
            width: "10%",
            margin: "10px auto 0 auto", // Centered and responsive margins
          }}
        />
      </p>
    </div>
  ))}
</Carousel>

    </div>
  )
}
