import React, { useEffect } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import SendIcon from '@mui/icons-material/Send'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import NearMeIcon from '@mui/icons-material/NearMe'
import emailjs from '@emailjs/browser'
import Button from '@mui/material/Button'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import SwipeableViews from 'react-swipeable-views'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import PropTypes from 'prop-types'
import './custom.css'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'

import Paper from '@mui/material/Paper'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import { useTheme } from '@mui/material/styles'
// import { TabsUnstyled } from '@mui/base'

import GalleryTabs from './components/GalleryTabs'
import CommonContent from './CommonRouter/commonContent'
import MakersSlider from './components/MakersSlider'
import OurBlog from './components/OurBlog'
import MediaHouses from './components/MediaHouses'

import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'

import Input from '@mui/material/Input'
import PinDropIcon from '@mui/icons-material/PinDrop'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import TwitterIcon from '@mui/icons-material/Twitter'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

import Register from './components/register'
import Requirements from './components/requirements'
import Thankyou from './components/thankyou'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useState, useRef } from 'react'
import { ImageListItem, styled, TextField } from '@mui/material'
import TestimonialsContent from './components/tesimonials'
import Form1 from './components/form1'
import Form2 from './components/form2'
import AboutPopup from './components/aboutPopUp'
import GalleryPopup from './components/galleryPopup'
import ServicesPopup from './components/servicesPopup'
import { useNavigate } from 'react-router'
import MainHeader from './CommonRouter/header'
import Footer from './CommonRouter/footer'

const clientsImg = [
  {
    img: '/images/cl1.png',
    title: 'shotting'
  },
  {
    img: '/images/cl2.png',
    title: 'production'
  },

  {
    img: '/images/cl3.png',
    title: 'production'
  },
  {
    img: '/images/cl4.png',
    title: 'studio'
  },
  {
    img: '/images/cl5.png',
    title: 'studio'
  },

  {
    img: '/images/cl6.png',
    title: 'production'
  },

  {
    img: '/images/cl7.png',
    title: 'production'
  }
]

const darkTheme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#C19B24',
      contrastText: '#ffffff'
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#C19B24'
    }
  },
  typography: {
    subheading: {
      color: '#C19B24',
      font: 'normal normal normal 12px/20px Playfair Display !important'
    },
    avname: {
      font: 'normal normal 500 20px/34px Playfair Display !important'
    },
    number: {
      font: 'normal normal bold 36px/77px Josefin Sans !important',
      color: '#fff'
    },
    number_lbl: {
      font: 'normal normal 500 20px/74px Playfair Display',
      color: '#fff'
    },
    wsh: {
      color: '#fff',
      font: 'normal normal bold 21px/45px Josefin Sans !important'
    },
    wsp: {
      color: '#fff',
      font: 'normal normal normal 16px/28px Playfair Display !important'
    },
    address: {
      font: 'normal normal normal 16px/32px Josefin Sans !important'
    }
  }
})
const ariaLabel = { 'aria-label': 'description' }
const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  background: 'none',
  boxShadow: 'none',
  [theme.breakpoints.down('md')]: {
    backgroundColor: '#27292A'
  }
}))
const StyledToolbar = styled(Toolbar)({
  backgroundColor: '#27292A', //#307192
  color: '#fff'
})
// const NoDivider = styled(Divider)({
//   width: '58px',
//   height: '1px',
//   background: '#707070',
//   margin: 'auto'
// })
// const GalTab = styled(Tab)({
//   font: 'normal normal normal 16px/11px Josefin Sans !important',
//   fontSize: '40px'
// })

// const ActBtn = styled('button')(({ theme }) => ({
//   position: 'absolute',
//   top: '50%',
//   left: '50%',

//   fontSize: '16px',
//   padding: '12px 24px',
//   border: 'none',
//   cursor: 'pointer'
// }))
// const FooterItem = styled(ListItem)({
//   font: 'normal normal normal 16px/32px Playfair Display',
//   paddingLeft: '0'
// })

const drawerWidth = 240
const navItems = ['Home', 'About Us', 'Our Services', 'Contact']

const itemData = [
  {
    img: '../images/ban1.png',
    title: 'Breakfast',
    author: '@bkristastucchio',
    rows: 2,
    cols: 2,
    featured: true
  },
  {
    img: '../images/ban2.png',
    title: 'Burger',
    author: '@rollelflex_graphy726'
  }
]

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

function Child_Test() {
  const aboutRef = useRef()
  const homeRef = useRef()
  const serviceRef = useRef()
  const teamRef = useRef()
  const contactRef = useRef()
  const formRef = useRef()
  const form = useRef()
  const navigate = useNavigate()

  const sendEmail = e => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_lcikxhd',
        'template_ra4yf1l',
        form.current,
        'hHk1gJukMYaFgCsxP'
      )
      .then(
        result => {
          console.log(result.text)
        },
        error => {
          console.log(error.text)
        }
      )
    e.target.reset()
  }

  const [open1, setOpen1] = React.useState(false)
  const [openAbout, setOpenAbout] = React.useState(false)
  const [openGallery, setOpenGallery] = React.useState(false)
  const [openServices, setOpenServices] = React.useState(false)
  const [open2, setOpen2] = React.useState(false)
  const [headR, setHeadR] = React.useState(false)
  const [tabv, setTabv] = React.useState("ho")
  React.useEffect(() => {
    const onScroll = e => {
      if (document.body.scrollTop > 100) {
        setHeadR(true)
      } else {
        setHeadR(false)
      }
    }
    window.addEventListener('scroll', onScroll, true)
    return () => window.removeEventListener('scroll', onScroll, true)
  }, [])

  const handleClickOpen1 = () => {
    setOpen1(true)
  }

  const handleClose1 = () => {
    setOpen1(false)
  }

  const handleClickOpen2 = () => {
    setOpen2(true)
  }

  const handleClickOpenAbout = () => {
    setOpenAbout(true)
  }
  const handleClickOpenGallery = () => {
    setOpenGallery(true)
  }
  const handleCloseGallery = () => {
    setOpenGallery(false)
  }

  const handleClickOpenServices = () => {
    setOpenServices(true)
  }
  const handleCloseServices = () => {
    setOpenServices(false)
  }

  const handleCloseAbout = () => {
    setOpenAbout(false)
  }

  const handleClose2 = () => {
    setOpen2(false)
  }

  const ScrolToAbout = event => {
    aboutRef.current.scrollIntoView({ behavior: 'smooth' })
    setTabv("ab");
  }
  const ScrolToService = event => {
    serviceRef.current.scrollIntoView({ behavior: 'smooth' })
    setTabv("se");
  }

  const ScrolToTeam = event => {
    teamRef.current.scrollIntoView({ behavior: 'smooth' })
    setTabv("te");
  }

  const ScrolToContact = event => {
    contactRef.current.scrollIntoView({ behavior: 'smooth' })
    setTabv("co");
  }

  const ScrolToHome = event => {
    homeRef.current.scrollIntoView({ behavior: 'smooth' })
    setTabv("ho");
  }

  const [isShownReq, setIsShownReq] = useState(false)
  const [isShown, setIsShown] = useState(false)
  const handleClick = event => {
    // 👇️ toggle shown state
    // *** as the normal message

    setIsShown(current => !current)
    setIsShownReq(false)
    setMessage('')
    setTimeout(() => {
      // ***
      formRef.current.scrollIntoView({ behavior: 'smooth' })
    }, 100)
    // 👇️ or simply set it to true
    // setIsShown(true);
  }
  const handleClickReq = event => {
    // 👇️ toggle shown state

    setIsShownReq(current => !current)
    setIsShown(false)
    setMessage('')
    setTimeout(() => {
      // ***
      formRef.current.scrollIntoView({ behavior: 'smooth' })
    }, 100)
    // 👇️ or simply set it to true
    // setIsShown(true);
  }

  const [message, setMessage] = useState()
  const thanks = e => {
    setMessage(e)
    setIsShown(false)
    setIsShownReq(false)
  }

  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [value, setValue] = React.useState(0)
  const theme = useTheme()
  const [dense, setDense] = React.useState(true)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant='h6' sx={{ my: 2 }}>
        <img src='logo.png'></img>
      </Typography>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'left' }}>
            <ListItemText primary='Home' onClick={(e) => handleClick(1)} />
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton
            onClick={event => ScrolToAbout(event)}
            sx={{ textAlign: 'left' }}
          >
            <ListItemText primary='About Us' onClick={(e) => handleClick(2)} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'left' }}>
            <ListItemText primary='Our Services' onClick={(e) => handleClick(3)} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'left' }}>
            <ListItemText primary='Contact Us' onClick={(e) => handleClick(4)} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  )

  return (
    <div className='App'>
      <HelmetProvider>
        <ThemeProvider theme={darkTheme}>

          {/* <MainHeader /> */}

          {/* <img src="images/ban1.png" /> */}
          {/* <Box component='nav'>
            <Drawer
              variant='temporary'
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth
                }
              }}
            >
              {drawer}
            </Drawer>
          </Box> */}

          {/* <Box>
            <Helmet>
              <script src='banner.js'></script>
            </Helmet>
            <div
              // ref={homeRef}
              class='banner container'
              style={{ height: '750px' }}
            >
              <div class='split left'>
                <h2 style={{ color: '#fff' }}>Post Production House</h2>
                <h1 className='chkTitle' style={{ color: '#fff' }}>
                  LET’S MAKE GREAT THINGS TOGETHER
                </h1>
                <a
                  // onClick={event => handleClickReq(event)}
                  onClick={event => handleClickOpen1(event)}
                  className='button onlyBtn newButton'
                // style={{ paddingBottom: '15px' }}
                >
                  Your Requirements
                </a>
              </div>
              <div class='split right'>
                <h2 style={{ color: '#fff' }}> Virtual Production</h2>
                <h1 className='chkTitle' style={{ color: '#fff' }}>
                  {' '}
                  FILMING TAKEN TO THE NEXT LEVEL{' '}
                </h1>
                <a
                  // onClick={event => handleClick(event)}
                  onClick={event => handleClickOpen2(event)}
                  className='button onlyBtn newButton'
                >
                  Get Connected
                </a>
              </div>
            </div>
          </Box> */}

          <Container ref={formRef} sx={{ maxWidth: { lg: '1400px' } }}>
            {isShown && <Register thanks={thanks} />}
            {isShownReq && <Requirements thanks={thanks} />}

            <Thankyou message={message} />
            <ToastContainer />
          </Container>

          <Container sx={{ maxWidth: { lg: '1400px' }, marginTop: '40px' }}>
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              mb={5}
            >
              <Grid item xs='2'></Grid>
              <Grid
                item
                xs='8'
                style={{
                  textAlign: 'center',
                  fontSize: '24px',
                  lineHeight: '36px'
                }}
              >
                Welcome to the world of Georgia Filming production, where
                creativity meets technology to bring your ideas to life. Media
                production encompasses a wide range of services, including
                video production, audio production, animation, Editing, and
                post-production. Georgia Filming is a business that
                specialises in developing and producing many kinds of media
                content, including movies, TV shows, documentaries,
                commercials, music videos, and more.
              </Grid>
              <Grid item xs='2'></Grid>
            </Grid>
          </Container>

          <Container sx={{ maxWidth: { lg: '1400px' } }}>
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'

            >
              <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <GalleryTabs />
              </Box>
            </Grid>
          </Container>

          <Box component='main' sx={{ p: 2 }} style={{ marginTop: '12px' }}>
            <Container sx={{ maxWidth: { lg: '1400px' } }}>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                mb={5}
              >
                <Grid item xs='2'></Grid>
                <Grid
                  item
                  xs='8'
                  style={{
                    textAlign: 'center',
                    fontSize: '24px',
                    lineHeight: '36px'
                  }}
                >
                  <Typography variant='h2' className='title2' >
                    WHY CHOOSE US
                  </Typography>
                  <p style={{marginTop:'12px'}}>Delivering strategic, visually engaging videos.
                    Combining local talent and European expertise for superior quality.
                    Over 12 years of experience with 650+ successful projects.
                  </p>

                </Grid>
                <Grid item xs='2'></Grid>
              </Grid>
            </Container>
          </Box>

          <Grid
            container
            py={5}
            spacing={1}
            style={{
              background:
                "url('../images/testimonial-bg.png') center center no-repeat",
              color: '#fff'
            }}
          >
            <Container sx={{ maxWidth: { lg: '900px' } }}>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                my={6}
              >
                <Grid item xs={12}>
                  <Typography
                    variant='h5'
                    component='div'
                    style={{ color: '#C19B24' }}
                  >
                    TESTIMONIAL
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant='h2'
                    className='title2'
                    sx={{ mt: 4 }}
                    style={{ textAlign: 'center' }}
                  >
                    WHAT CUSTOMER’S SAYS
                  </Typography>
                  <br />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src='/images/comm.png' />
                  </div>
                </Grid>

                <Grid item xs={12}>

                </Grid>
              </Grid>

              <TestimonialsContent />
            </Container>
          </Grid>

          <Container>
            <p style={{ textAlign: 'center', marginTop: "30px", fontSize: '24px', lineHeight: '36px' }}>
              Whether you are a business owner, a marketer, a content
              creator, or anyone looking to tell your story through media,
              we are here to help you bring your ideas to life. Welcome to
              the world of media production, where anything is possible.
            </p>
          </Container>

          <Box component='main' sx={{ p: 2 }}>
            <Container sx={{ maxWidth: { lg: '1400px' } }}>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
              >
                <Grid item xs={12}>
                  <Typography
                    variant='h2'
                    className='title2'
                    sx={{ mt: 4 }}
                    style={{ color: '#000' }}
                  >
                    OUR CLIENTS <br />{' '}
                    <div style={{ textAlign: 'center', fontSize: '16px' }}>
                      -------------------------------------
                    </div>
                  </Typography>
                  <p style={{ textAlign: 'center', fontSize: '24px' }}>
                    Who We Work With
                  </p>
                  <br />
                </Grid>
                <Grid container py={5} spacing={1} marginBottom={'30px'}>
                  <Container sx={{ maxWidth: { lg: '1200px' } }}>
                    <div style={{ marginBottom: 'px' }}>
                      <MediaHouses />
                    </div>
                  </Container>
                </Grid>

              </Grid>
            </Container>
          </Box>

        </ThemeProvider>
      </HelmetProvider>
    </div>
  )
}


function Test() {
  return (
    <CommonContent
      mainData={<Child_Test />}
      backgroundImage="https://georgiafilming.s3.us-east-1.amazonaws.com/6.png"
      imageContent="LET’S MAKE GREAT THINGS TOGETHER"
    />
  )
}


export default Test
