import React, { useEffect } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import SendIcon from '@mui/icons-material/Send'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import NearMeIcon from '@mui/icons-material/NearMe'
import emailjs from '@emailjs/browser'
import Button from '@mui/material/Button'
import { ThemeProvider, createTheme } from '@mui/material/styles'

import SwipeableViews from 'react-swipeable-views'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import PropTypes from 'prop-types'
import './custom.css'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'

import Paper from '@mui/material/Paper'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import { useTheme } from '@mui/material/styles'
// import { TabsUnstyled } from '@mui/base'

import GalleryTabs from './components/GalleryTabs'
import MakersSlider from './components/MakersSlider'
import OurBlog from './components/OurBlog'
import MediaHouses from './components/MediaHouses'

import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'

import Input from '@mui/material/Input'
import PinDropIcon from '@mui/icons-material/PinDrop'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import TwitterIcon from '@mui/icons-material/Twitter'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

import Register from './components/register'
import Requirements from './components/requirements'
import Thankyou from './components/thankyou'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useState, useRef } from 'react'
import { ImageListItem, styled, TextField } from '@mui/material'
import TestimonialsContent from './components/tesimonials'
import Form1 from './components/form1'
import Form2 from './components/form2'
import AboutPopup from './components/aboutPopUp'
import GalleryPopup from './components/galleryPopup'
import ServicesPopup from './components/servicesPopup'
import CommonRouters from './CommonRouter/router'
// import { useNavigate } from 'react-router'

const clientsImg = [
  {
    img: '/images/cl1.png',
    title: 'shotting'
  },
  {
    img: '/images/cl2.png',
    title: 'production'
  },

  {
    img: '/images/cl3.png',
    title: 'production'
  },
  {
    img: '/images/cl4.png',
    title: 'studio'
  },
  {
    img: '/images/cl5.png',
    title: 'studio'
  },

  {
    img: '/images/cl6.png',
    title: 'production'
  },

  {
    img: '/images/cl7.png',
    title: 'production'
  }
]

const darkTheme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#C19B24',
      contrastText: '#ffffff'
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#C19B24'
    }
  },
  typography: {
    subheading: {
      color: '#C19B24',
      font: 'normal normal normal 12px/20px Playfair Display !important'
    },
    avname: {
      font: 'normal normal 500 20px/34px Playfair Display !important'
    },
    number: {
      font: 'normal normal bold 36px/77px Josefin Sans !important',
      color: '#fff'
    },
    number_lbl: {
      font: 'normal normal 500 20px/74px Playfair Display',
      color: '#fff'
    },
    wsh: {
      color: '#fff',
      font: 'normal normal bold 21px/45px Josefin Sans !important'
    },
    wsh1: {
      color: 'white',
      font: 'normal normal bold 21px/45px Josefin Sans !important'
    },
    wsp: {
      color: '#fff',
      font: 'normal normal normal 16px/28px Playfair Display !important'
    },
    address: {
      font: 'normal normal normal 16px/32px Josefin Sans !important'
    }
  }
})
const ariaLabel = { 'aria-label': 'description' }
const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  background: 'none',
  boxShadow: 'none',
  [theme.breakpoints.down('md')]: {
    backgroundColor: '#27292A'
  }
}))
const StyledToolbar = styled(Toolbar)({
  backgroundColor: '#27292A', //#307192
  color: '#fff'
})
// const NoDivider = styled(Divider)({
//   width: '58px',
//   height: '1px',
//   background: '#707070',
//   margin: 'auto'
// })
// const GalTab = styled(Tab)({
//   font: 'normal normal normal 16px/11px Josefin Sans !important',
//   fontSize: '40px'
// })

// const ActBtn = styled('button')(({ theme }) => ({
//   position: 'absolute',
//   top: '50%',
//   left: '50%',

//   fontSize: '16px',
//   padding: '12px 24px',
//   border: 'none',
//   cursor: 'pointer'
// }))
// const FooterItem = styled(ListItem)({
//   font: 'normal normal normal 16px/32px Playfair Display',
//   paddingLeft: '0'
// })

const drawerWidth = 240
const navItems = ['Home', 'About Us', 'Our Services', 'Contact']

const itemData = [
  {
    img: '../images/ban1.png',
    title: 'Breakfast',
    author: '@bkristastucchio',
    rows: 2,
    cols: 2,
    featured: true
  },
  {
    img: '../images/ban2.png',
    title: 'Burger',
    author: '@rollelflex_graphy726'
  }
]

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

function App() {
  const aboutRef = useRef()
  const homeRef = useRef()
  const serviceRef = useRef()
  const teamRef = useRef()
  const contactRef = useRef()
  const formRef = useRef()
  const form = useRef()
  // const navigate = useNavigate()

  const sendEmail = e => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_lcikxhd',
        'template_ra4yf1l',
        form.current,
        'hHk1gJukMYaFgCsxP'
      )
      .then(
        result => {
          console.log(result.text)
        },
        error => {
          console.log(error.text)
        }
      )
    e.target.reset()
  }

  const [open1, setOpen1] = React.useState(false)
  const [openAbout, setOpenAbout] = React.useState(false)
  const [openGallery, setOpenGallery] = React.useState(false)
  const [openServices, setOpenServices] = React.useState(false)
  const [open2, setOpen2] = React.useState(false)
  const [headR, setHeadR] = React.useState(false)
  const [tabv, setTabv] = React.useState("ho")
  React.useEffect(() => {
    const onScroll = e => {
      if (document.body.scrollTop > 100) {
        setHeadR(true)
      } else {
        setHeadR(false)
      }
    }
    window.addEventListener('scroll', onScroll, true)
    return () => window.removeEventListener('scroll', onScroll, true)
  }, [])

  const handleClickOpen1 = () => {
    setOpen1(true)
  }

  const handleClose1 = () => {
    setOpen1(false)
  }

  const handleClickOpen2 = () => {
    setOpen2(true)
  }

  const handleClickOpenAbout = () => {
    setOpenAbout(true)
  }
  const handleClickOpenGallery = () => {
    setOpenGallery(true)
  }
  const handleCloseGallery = () => {
    setOpenGallery(false)
  }

  const handleClickOpenServices = () => {
    setOpenServices(true)
  }
  const handleCloseServices = () => {
    setOpenServices(false)
  }

  const handleCloseAbout = () => {
    setOpenAbout(false)
  }

  const handleClose2 = () => {
    setOpen2(false)
  }

  const ScrolToAbout = event => {
    aboutRef.current.scrollIntoView({ behavior: 'smooth' })
    setTabv("ab");
  }
  const ScrolToService = event => {
    serviceRef.current.scrollIntoView({ behavior: 'smooth' })
    setTabv("se");
  }

  const ScrolToTeam = event => {
    teamRef.current.scrollIntoView({ behavior: 'smooth' })
    setTabv("te");
  }

  const ScrolToContact = event => {
    contactRef.current.scrollIntoView({ behavior: 'smooth' })
    setTabv("co");
  }

  const ScrolToHome = event => {
    homeRef.current.scrollIntoView({ behavior: 'smooth' })
    setTabv("ho");
  }

  const [isShownReq, setIsShownReq] = useState(false)
  const [isShown, setIsShown] = useState(false)
  const handleClick = event => {
    // 👇️ toggle shown state
    // *** as the normal message

    setIsShown(current => !current)
    setIsShownReq(false)
    setMessage('')
    setTimeout(() => {
      // ***
      formRef.current.scrollIntoView({ behavior: 'smooth' })
    }, 100)
    // 👇️ or simply set it to true
    // setIsShown(true);
  }
  const handleClickReq = event => {
    // 👇️ toggle shown state

    setIsShownReq(current => !current)
    setIsShown(false)
    setMessage('')
    setTimeout(() => {
      // ***
      formRef.current.scrollIntoView({ behavior: 'smooth' })
    }, 100)
    // 👇️ or simply set it to true
    // setIsShown(true);
  }

  const [message, setMessage] = useState()
  const thanks = e => {
    setMessage(e)
    setIsShown(false)
    setIsShownReq(false)
  }

  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [value, setValue] = React.useState(0)
  const theme = useTheme()
  const [dense, setDense] = React.useState(true)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant='h6' sx={{ my: 2 }}>
        <img src='logo.png'></img>
      </Typography>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'left' }}>
            <ListItemText primary='Home' onClick={(e) => handleClick(1)} />
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton
            onClick={event => ScrolToAbout(event)}
            sx={{ textAlign: 'left' }}
          >
            <ListItemText primary='About Us' onClick={(e) => handleClick(2)} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'left' }}>
            <ListItemText primary='Our Services' onClick={(e) => handleClick(3)} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'left' }}>
            <ListItemText primary='Contact Us' onClick={(e) => handleClick(4)} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  )

  return (
    <div className='App'>
      <CommonRouters />
    </div>
  )
}
export default App
