import React from 'react';
import CommonContent from '../CommonRouter/commonContent';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container, Typography } from '@mui/material';
import DialogWithContactForm from '../components/DialogWithContactForm';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('md')]: {
        backgroundColor: '#1A2027',
    },
}));

const ChildCExplainer = () => {
    return (
        <>
            <Box sx={{ flexGrow: 1, paddingY: { xs: '16px', md: '40px' }, px: { xs: '16px', md: '40px' } }}>
                <Container>
                    <Grid container spacing={4} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography
                                variant='h2'
                                className='title2'
                                style={{ color: 'black' }}
                            >
                                Explainer Video Production
                            </Typography>
                            <Typography
                                sx={{ my: 2 }}
                                style={{
                                    fontSize: '18px',
                                    color: '#000'
                                }}
                            >
                                Explainer videos designed to showcase your value and drive audience engagement.
                            </Typography>
                            {/* <Typography
                            variant="h5"
                            sx={{
                                textTransform: 'uppercase',
                                marginBottom: 2,
                                fontWeight: 'bold',
                                color: 'text.primary',
                            }}
                        >
                            Explainer videos designed to showcase your value and drive audience engagement.
                        </Typography> */}
                            <div style={{ marginTop: '20px' }}>
                                <DialogWithContactForm PopupContent="Get Info & Pricing" color='#333333' textColor={'white'} />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <video
                                    // controls
                                    autoPlay
                                    muted
                                    loop
                                    width="80%"
                                    style={{
                                        borderRadius: '8px',
                                        boxShadow: 3,
                                    }}
                                >
                                    <source src="https://georgiafilming.s3.us-east-1.amazonaws.com/ITTStar_Promo_Video.mp4" />
                                </video>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Container>
                    <Grid container spacing={4} justifyContent="center" marginTop="20px">
                        <Grid item xs={12} sm={12} md={6}>
                            <Box sx={{ display: 'inline-block', justifyContent: 'center', alignItems: 'center' }}>
                                <div>
                                    <img
                                        style={{ borderRadius: '20px', width: '80%', height:'400px' }}
                                        src="https://georgiafilming.s3.us-east-1.amazonaws.com/Transforming-data-into-strategic-assets.png"
                                        alt="Thumbnail"
                                    />
                                </div>
                                <div style={{ marginTop: '40px' }}>
                                    <video
                                        // controls
                                        autoPlay
                                        muted
                                        loop
                                        width="80%"
                                        maxWidth="600px"
                                        style={{
                                            borderRadius: '20px',
                                            boxShadow: 3,
                                        }}
                                    >
                                        <source src="https://georgiafilming.s3.us-east-1.amazonaws.com/GenAI_Webinar_P1.mp4" />
                                    </video>
                                </div>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}  alignContent={'center'}>
                            <Typography
                                variant='h4'
                                fontFamily={'Josefin Sans !important'}
                                // className='title2'
                                style={{ color: 'black',fontWeight:500}}
                            >
                               5 high-impact results you get from a 60-second explainer video
                            </Typography>
                           
                            <Typography  sx={{ marginTop: '20px',fontSize: '18px',
                                    color: '#000' }}>
                                Explainer videos deliver fast results. Here’s how they work for you:
                            </Typography>
                            <div style={{ marginTop: '20px' }}>
                                <h3>1. &nbsp;Attract and engage new Cliets</h3>
                                <p style={{ marginTop: '10px' }}>Grab attention and drive traffic to your website from social media or other channels, converting viewers into visitors.</p>
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <h3>2. &nbsp;Explain problems clearly</h3>
                                <p style={{ marginTop: '10px' }}>Communicate the exact challenges your product or service addresses, ensuring your audience understands how you can solve their needs.</p>
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <h3>3. &nbsp;Stand out from the competition</h3>
                                <p style={{ marginTop: '10px' }}>Highlight what sets you apart and emphasize why your solution is the right choice for your audience.</p>
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <h3>4. &nbsp;Show the benefits they’ll gain</h3>
                                <p style={{ marginTop: '10px' }}>Make the benefits of your product or service tangible so your audience can see exactly what they’ll achieve.</p>
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <h3>5. &nbsp;Motivate immediate action</h3>
                                <p style={{ marginTop: '10px' }}>Give a compelling reason for viewers to take the next step—whether it’s contacting you, signing up, or scheduling a demo.</p>
                            </div>
                            {/* <div style={{ marginTop: '20px' }}>
                            <DialogWithContactForm PopupContent="Get Info & Pricing" color='success'/>
                        </div> */}
                        </Grid>
                    </Grid>
                </Container>

            </Box>
            <div style={{ backgroundColor: '#f5f6f7', padding: '40px', textAlign: 'center' }}>
                <DialogWithContactForm PopupContent="Get a Quote" color='#333333'
                    textColor={'white'} />
                <div style={{ marginTop: '20px' }}>
                    <Typography variant='p' sx={{ fontWeight: 'bold', fontSize: '20px' }} >Discover your exact investment for improved results.</Typography>
                </div>
            </div>
        </>
    );
};

function Explainer_Video() {
    const backgroundImage = "https://georgiafilming.s3.us-east-1.amazonaws.com/7.png"
    return (
        <>
            <CommonContent mainData={<ChildCExplainer


            />}
                backgroundImage={backgroundImage}

            />
        </>
    );
}

export default Explainer_Video;
