import React from 'react';
import CommonContent from '../CommonRouter/commonContent';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography, Button, Container } from '@mui/material';
import DialogWithContactForm from '../components/DialogWithContactForm';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('md')]: {
        backgroundColor: '#1A2027',
    },
}));

const ChildAnimation_Services = () => {
    return (
        <>
            <Box sx={{ flexGrow: 1, paddingY: { xs: '16px', md: '40px' }, px: { xs: '16px', md: '40px' } }}>
                <Container style={{ display: "flex", justifyContent: "center" }}>
                    <Grid container spacing={4} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography
                                variant="h5"
                                className='title2'
                                sx={{
                                    marginBottom: 2,
                                }}
                            >
                                Animation Services
                            </Typography>
                            <Typography variant="body1" sx={{ marginBottom: '20px' }}>
                                We specialize in working with agencies and brands to fulfill animation and motion graphics needs.
                            </Typography>
                            <div style={{ marginTop: '20px' }}>
                                <DialogWithContactForm PopupContent="Get Info & Pricing" color='#333333'
                            textColor={'white'} />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <video
                                    autoPlay
                                    muted
                                    loop
                                    width="100%"
                                    style={{
                                        borderRadius: '20px',
                                        boxShadow: 3,
                                    }}
                                >
                                    <source src="https://georgiafilming.s3.us-east-1.amazonaws.com/Fletronix_03-13-24.mp4" />
                                </video>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Grid sx={{ marginTop: '40px', textAlign: 'center' }} item xs={12} sm={12} md={12}>
                    <Typography
                        variant="h5"
                        sx={{
                            marginBottom: 2,
                            fontWeight: 'bold',
                            color: 'text.primary',
                        }}
                    >
                        Handling all your animation production needs.
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '20px' }}>
                        Need full animation services or just one step? Our team has you covered.
                    </Typography>
                </Grid>

                <Container style={{ display: "flex", justifyContent: "center" }}>
                    <Grid container justifyContent="center" marginTop="10px" style={{ backgroundColor: '#f5f6f7', borderRadius: '20px', padding: '20px' }}>
                        <Grid item xs={12} sm={12} md={6}>
                            <div>
                                <ul style={{ lineHeight: '1.8' }}>
                                    <li>Concept & Story Creation</li>
                                    <li>Scriptwriting & Storyboarding</li>
                                    <li>2D & 3D Animation</li>
                                    <li>Motion Graphics Design</li>
                                    <li>Voiceover & Sound Design</li>
                                    <li>Video Editing & Final Output</li>
                                </ul>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <div>
                                <img
                                    style={{ borderRadius: '20px', width: '100%' }}
                                    src="/images/animation-image.png"
                                    alt="Animation Process"
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Container>
                <Container>
                    <Grid sx={{ marginTop: '40px' }} item xs={12} sm={12} md={12}>
                        <Typography
                            variant="h5"
                            sx={{
                                marginBottom: 2,
                                fontWeight: 'bold',
                                color: 'text.primary',
                            }}
                        >
                            Selected Work
                        </Typography>
                    </Grid>
                </Container>


                <Container style={{ display: "flex", justifyContent: "center", marginTop: '30px' }}>
                    <Grid container spacing={4} justifyContent="center"  >
                        <Grid item xs={12} sm={12} md={6}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <video
                                    controls
                                    muted
                                    loop
                                    width="100%"
                                    style={{
                                        borderRadius: '20px',
                                        boxShadow: 3,
                                    }}
                                >
                                    <source src="https://georgiafilming.s3.us-east-1.amazonaws.com/Data Analytics Course.mp4" />
                                </video>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <video
                                    controls
                                    muted
                                    loop
                                    width="100%"
                                    style={{
                                        borderRadius: '20px',
                                        boxShadow: 3,
                                    }}
                                >
                                    <source src="https://georgiafilming.s3.us-east-1.amazonaws.com/ITTStar_Promo_Video.mp4" />
                                </video>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>

                <Container style={{ display: "flex", justifyContent: "center", marginTop: '30px' }}>
                    <Grid container spacing={4} justifyContent="center"  >
                    <Grid item xs={12} sm={12} md={6}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <video
                                    controls
                                    muted
                                    loop
                                    width="100%"
                                    style={{
                                        borderRadius: '20px',
                                        boxShadow: 3,
                                    }}
                                >
                                    <source src="https://georgiafilming.s3.us-east-1.amazonaws.com/Whatsapp+wishes.mp4" />
                                </video>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <video
                                    controls
                                    muted
                                    loop
                                    width="100%"
                                    style={{
                                        borderRadius: '20px',
                                        boxShadow: 3,
                                    }}
                                >
                                    <source src="https://georgiafilming.s3.us-east-1.amazonaws.com/Final_LuxuryGoods_Video.mp4" />
                                </video>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>


                <Container style={{ display: "flex", justifyContent: "center", marginTop: '30px' }}>
                    <Grid container spacing={4} justifyContent="center"  >
                    <Grid item xs={12} sm={12} md={6}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <video
                                    controls
                                    muted
                                    loop
                                    width="100%"
                                    style={{
                                        borderRadius: '20px',
                                        boxShadow: 3,
                                    }}
                                >
                                    <source src="https://georgiafilming.s3.us-east-1.amazonaws.com/Data+Analytics+Course.mp4" />
                                </video>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <video
                                    controls
                                    muted
                                    loop
                                    width="100%"
                                    style={{
                                        borderRadius: '20px',
                                        boxShadow: 3,
                                    }}
                                >
                                    <source src="https://georgiafilming.s3.us-east-1.amazonaws.com/VDI+Video+-+Youtube+.mp4" />
                                </video>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>





            </Box>

            <div style={{ backgroundColor: '#f5f6f7', padding: '40px 0', textAlign: 'center' }}>
                <DialogWithContactForm PopupContent="Get a Quote" color='#333333'
                            textColor={'white'} />
                <div style={{ marginTop: '20px' }}>
                    <Typography variant='p' sx={{fontWeight:'bold',fontSize:'20px'}} >Discover your exact investment for improved results.</Typography>
                </div>
            </div>
        </>
    );
};

function Animation_Services() {
    const backgroundImage = "https://georgiafilming.s3.us-east-1.amazonaws.com/5.png"
    return (
        <>
            <CommonContent mainData={<ChildAnimation_Services
               
            />}
            backgroundImage={backgroundImage}
             />

        </>
    );
}

export default Animation_Services;
