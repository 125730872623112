import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Register from './register'
import { Container, Grid, styled, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'

const Item = styled(Paper)(({ theme }) => ({
  // padding: theme.spacing(1),
  // textAlign: 'center',
  // background: 'none',
  // boxShadow: 'none',
  // [theme.breakpoints.down('md')]: {
  //   backgroundColor: '#27292A'
  // }
}))

export default function ServicesPopup (props) {
  return (
    <div>
      {/* <Button variant="outlined" onClick={props.handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        className='dilogpopup Ditital'
        open={props.openServices}
        style={{ width: '100%' }}
      >
        <DialogActions style={{ position: 'relative' }}>
          <Button
            onClick={props.handleCloseServices}
            style={{
              background: 'red',
              color: '#fff',
              width: '1%',
              position: 'absolute',
              right: '10px',
              top: '10px'
            }}
          >
            X
          </Button>
        </DialogActions>
        <Grid
          container
          py={5}
          spacing={1}
          sx={{
            background: {
              xs: '#27292A',
              md: "url('images/servicein.png') center center no-repeat"
            }
          }}
          style={{ color: '#fff' }}
        >
          <Container sx={{ maxWidth: { lg: '1400px' } }}>
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              my={8}
            >
              <Grid item xs={12}>
                <Typography
                  variant='h2'
                  className='title2'
                  sx={{ mt: 4 }}
                  style={{
                    background: '#778ad3',
                    padding: '4%',
                    width: '100%'
                  }}
                >
                  Our Services
                </Typography>
                {/* <div style={{ textAlign: 'center', fontSize: '16px' }}>
                  -----------------
                </div> */}
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid container>
          <Grid
            // ref={serviceRef}
            container
            spacing={1}
            sx={{
              background: {
                xs: '#27292A',
                md: "url('images/bg-service.jpg') center center no-repeat"
              }
            }}
            style={{ color: '#fff' }}
          >
            <Container sx={{ maxWidth: { lg: '1400px' } }}>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                my={6}
              >
                {/* <Grid item xs={12}>
                  <Typography
                    variant='h2'
                    className='title2'
                    sx={{ mt: 4 }}
                    style={{ color: '#fff' }}
                  >
                    SERVICES <br />{' '}
                    <div style={{ textAlign: 'center', fontSize: '16px' }}>
                      -----------------
                    </div>
                  </Typography>
                </Grid> */}

                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Typography
                    sx={{ my: 2 }}
                    style={{
                      fontSize: '16px',
                      textAlign: 'center',
                      maxWidth: '70%'
                    }}
                  >
                    We understand that in today's digital age, having a robust
                    online presence is crucial for any business to stay relevant
                    and competitive. Therefore, our focus is on providing
                    customised solutions that cater to the unique needs of each
                    of our clients. We understand that every project is
                    different, and we take the time to understand your goals,
                    your audience, and your brand before we start working on
                    your project.
                  </Typography>
                </Grid>

                <Grid container py={5} spacing={1}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Item sx={{ m: 4 }}>
                      <img
                        src='/images/multimedia.png'
                        style={{ height: '48px', marginBottom: '18px' }}
                      />
                      <Typography variant='wsh' component='p'>
                        HIRE PRODUCTION
                      </Typography>
                      <Typography variant='wsp' component='p'>
                        Corporate Videos, Ad-Films/Digital Films, Social Media
                        Videos, Feature Films, Short Films, Music Videos, Video
                        Interviews, Documentaries, Video Editing
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Item sx={{ m: 4 }}>
                      <img
                        src='/images/film.png'
                        style={{ height: '48px', marginBottom: '18px' }}
                      />
                      <Typography variant='wsh' component='p'>
                        ANIMATION & XR / VR
                      </Typography>
                      <Typography variant='wsp' component='p'>
                        2D and 3D animation Infographics and motion Graphics
                        Stop motion videos. Whiteboard videos. Extended Reality
                        (XR) Services & Virtual Production.
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Item sx={{ m: 4 }}>
                      <img
                        src='/images/record.png'
                        style={{ height: '48px', marginBottom: '18px' }}
                      />
                      <Typography variant='wsh' component='p'>
                        RECORDING STUDIO
                      </Typography>
                      <Typography variant='wsp' component='p'>
                        Recording Studio Services, Recording, Mixing &
                        Mastering, Production & Sound Design, Corporate Event
                        Entertainment, Songwriting & Composition
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Item sx={{ m: 4 }}>
                      <img
                        src='/images/movie.png'
                        style={{ height: '48px', marginBottom: '18px' }}
                      />
                      <Typography variant='wsh' component='p'>
                        EVENTS
                      </Typography>
                      <Typography variant='wsp' component='p'>
                        Award Ceremonies, Film Premieres, Film Festivals,
                        Fashion Shows and Pop-Up Experientials, Music Concerts
                        and Live Events, Corporate Functions, Theatre
                        Productions, Outdoor Festivals, Brand and Product
                        Launches
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Item sx={{ m: 4 }}>
                      <img
                        src='/images/ui-video-play.png'
                        style={{ height: '48px', marginBottom: '18px' }}
                      />
                      <Typography variant='wsh' component='p'>
                        BRANDING STRATEGY
                      </Typography>
                      <Typography variant='wsp' component='p'>
                        Logo design, Brand messaging, Brand positioning, Brand
                        voice, Style guide, Social Media Branding.
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Item sx={{ m: 4 }}>
                      <img
                        src='/images/video-cam.png'
                        style={{ height: '48px', marginBottom: '18px' }}
                      />
                      <Typography variant='wsh' component='p'>
                        POST PRODUCTION
                      </Typography>
                      <Typography variant='wsp' component='p'>
                        Video Editing, Sound Editing, Graphic Designing, Voice
                        overs, Color Grading, Content moderation.
                      </Typography>
                    </Item>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  )
}
