import React, { useEffect, useRef, useState } from "react";

const VideoThumbnailExtractor = () => {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [thumbnail, setThumbnail] = useState(null);

    const extractThumbnail = () => {
        const video = videoRef.current;
        const canvas = canvasRef.current;

        if (video && canvas) {
            // Set the canvas size to match the video dimensions
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            const ctx = canvas.getContext("2d");

            // Draw the current frame of the video onto the canvas
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

            // Convert the canvas content to a data URL
            const thumbnailDataURL = canvas.toDataURL("image/png");
            console.log(thumbnailDataURL,"thumbnailDataURL")
            setThumbnail(thumbnailDataURL);
        }
    };
    useEffect(() => {
        console.log(thumbnail,"thumbnail")
    }, [thumbnail])
    return (
        <div>
            {/* Video element to load and play the video */}
            <video
                ref={videoRef}
                src="https://georgiafilming.s3.us-east-1.amazonaws.com/DRS_Promo+2_11-03-23_F.mp4"
                controls
                onLoadedData={() => videoRef.current.currentTime = 1} // Seek to 1s for thumbnail
                style={{ width: "100%", maxWidth: "600px" }}
            />

            {/* Button to extract thumbnail */}
            <button onClick={extractThumbnail}>Extract Thumbnail</button>

            {/* Canvas element (hidden) */}
            <canvas ref={canvasRef} style={{ display: "none" }} />

            {/* Display the extracted thumbnail */}
            {thumbnail && (
                <div>
                    <h3>Thumbnail:</h3>
                    <img src={thumbnail} alt="Thumbnail" style={{ maxWidth: "100%" }} />
                </div>
            )}
        </div>
    );
};

export default VideoThumbnailExtractor;
