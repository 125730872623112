import React from 'react';
import CommonContent from '../CommonRouter/commonContent';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container, Typography } from '@mui/material';
import DialogWithContactForm from '../components/DialogWithContactForm';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('md')]: {
        backgroundColor: '#1A2027',
    },
}));

let items = [



    { id: 22, img: "https://georgiafilming.s3.us-east-1.amazonaws.com/Design_02 1.jpg" },
    { id: 22, img: "https://georgiafilming.s3.us-east-1.amazonaws.com/Design_02 2.jpg" },
    { id: 22, img: "https://georgiafilming.s3.us-east-1.amazonaws.com/3.png" },
    { id: 22, img: "https://georgiafilming.s3.us-east-1.amazonaws.com/Design_02-01.jpg" },
    { id: 22, img: "https://georgiafilming.s3.us-east-1.amazonaws.com/Design_01.jpg" },
    { id: 22, img: "https://georgiafilming.s3.us-east-1.amazonaws.com/Design_02-02.jpg" },
]

const ChildMotion_Graphics = () => {
    return (
        <>
            <Box sx={{ flexGrow: 1, paddingY: { xs: '16px', md: '40px' }, px: { xs: '16px', md: '40px' } }}>
                <Container>
                    <Grid container spacing={4} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography
                                variant='h2'
                                className=''
                                fontFamily={'Josefin Sans !important'}
                                lineHeight={'40px'}
                                style={{ color: 'black', fontSize: '36px', fontWeight: 'bold' }}
                            >
                                Graphic Designing with proven results
                            </Typography>
                            <Typography
                                sx={{ my: 2 }}
                                style={{
                                    fontSize: '18px',
                                    textAlign: '',
                                    color: '#000'
                                }}
                            >
                                From standout single designs to unlimited ongoing creativity, we ensure every project meets your vision and exceeds expectations. Let us bring your ideas to life!
                            </Typography>
                            <Typography variant="p" sx={{ marginTop: '20px' }}>

                            </Typography>
                            <div style={{ marginTop: '20px' }}>
                                <DialogWithContactForm PopupContent="Get Info & Pricing" color='#333333' textColor={'white'} />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <video
                                    // controls
                                    autoPlay
                                    muted
                                    loop
                                    width="80%"
                                    maxWidth="600px"
                                    style={{
                                        borderRadius: '8px',
                                        boxShadow: 3,
                                    }}
                                >
                                    <source src="https://georgiafilming.s3.us-east-1.amazonaws.com/For YT.mp4" />
                                </video>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box sx={{ padding: '20px 0px 40px 0px', color: 'white', backgroundColor: '#E6E9F3' }}>
                <Container sx={{ paddingY: '10px' }}>
                    <div >
                        <Typography
                            variant='h2'
                            className='title2'
                            style={{ color: 'black' }}
                        >
                            Work Examples
                        </Typography>
                        <Typography
                            sx={{ my: 2 }}
                            style={{
                                fontSize: '18px',
                                textAlign: '',
                                color: 'black'
                            }}
                        >
                            Graphic Designs That Deliver, Every Time!
                        </Typography>
                    </div>
                    <Grid container spacing={4} justifyContent="center" marginTop="0px">
                        {items.map((item, index) =>
                            <Grid item xs={12} sm={12} md={4}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>

                                    <img
                                        key={index}
                                        src={item.img}
                                        alt={`Item ${index}`}
                                        style={{
                                            width: "100%",
                                            height: "auto",
                                            borderRadius: "10px",
                                            objectFit: "cover",
                                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                        }}
                                    />

                                </Box>
                            </Grid>
                        )}
                    </Grid>



                </Container>
            </Box>
            <div style={{ backgroundColor: '#f5f6f7', padding: '40px', textAlign: 'center' }}>
                <DialogWithContactForm PopupContent="Get a Quote" color='#333333' textColor={'white'} />
                <div style={{ marginTop: '20px' }}>
                    <Typography variant='p' sx={{ fontWeight: 'bold', fontSize: '20px' }} >Discover your exact investment for improved results.</Typography>
                </div>
            </div>
        </>
    );
};

function Motion_Graphics() {
    const backgroundImage = "https://georgiafilming.s3.us-east-1.amazonaws.com/7.png"
    return (
        <>
            <CommonContent mainData={<ChildMotion_Graphics

            />}
                backgroundImage={backgroundImage} />
        </>
    );
}

export default Motion_Graphics;
