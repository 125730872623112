import * as React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CustomButtonGroupAsArrows } from './CustomArrows'
import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
const images = [{
  name: "Nanda Reddy",
  img: "https://georgiafilming.s3.us-east-1.amazonaws.com/team-main.png",
  des: "Director of Operations"
}, {
  name: "Damodar(Dan)",
  img: "https://georgiafilming.s3.us-east-1.amazonaws.com/image_processed.jpeg",
  des: "Executive Producer  (Production Head)"
}, {
  name: "Naveen Kumar",
  img: "https://georgiafilming.s3.us-east-1.amazonaws.com/maker-4.png",
  des: "Line Producer & Creative Head."
}, {
  name: "Sanjana",
  img: "https://georgiafilming.s3.us-east-1.amazonaws.com/Sanjana_processed.png",
  des: "Lead Coordinator (USA)"
}

];
export default function MakersSlider() {

  return (
    <div>
      <Grid container spacing={4}>
        {
          images.map((res) =>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <Card
                sx={{
                  height:'540px',
                  overflow: 'hidden',
                  border: '1px solid',
                  borderColor: 'grey.300',
                  boxShadow: 3,
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0 4px 25px rgba(0, 0, 0, 0.1)',
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="400"
                  image={res.img}
                  alt="Damodar S K (Dan)"
                  // sx={{ : '', }}
                />
                <CardContent>
                  <Typography variant="h4" color="primary">
                    {res.name}
                  </Typography>
                  <Typography variant="h6" fontWeight="medium" mt={1}>
                    {res.des}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )
        }

      </Grid>
      {/* <Carousel
        additionalTransfrom={0}
        arrows={false} autoPlay
        autoPlaySpeed={4000}
        centerMode={false}
        className=""
        containerClass="container-padding-bottom"
        customButtonGroup={<CustomButtonGroupAsArrows />}
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite={true}
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024
            },
            items: 5,
            partialVisibilityGutter: 40
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0
            },
            items: 3,
            partialVisibilityGutter: 30
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464
            },
            items: 4,
            partialVisibilityGutter: 30
          }
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {images.slice(0, 7).map(image => {
          return (
            <div style={{ margin: '0px 12px 0px 12px' }}>
              <img
                draggable={false}
                style={{ width: "100%", height: "100%", }}
                src={image?.img}
              />
              <div>
                <h4 style={{ float: "right" }}>{image?.name}</h4><br />
                <h5 style={{ float: "right" }}>-------</h5><br />
                <p style={{ float: "right", fontSize: "12px", fontFamily: "sans-serif" }}>{image?.des}</p>
              </div>
            </div>
          );
        })}
      </Carousel> */}
    </div>
  );
}