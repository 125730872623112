import React, { useRef, useState } from 'react'
import CommonContent from '../CommonRouter/commonContent'
import { Box, Container, Grid, ImageList, ImageListItem, Typography } from '@mui/material'
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@emotion/react';


function VideoCard({ src }) {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    return (
        <div
            style={{
                position: "relative",
                borderRadius: '10%',
                width: '100%',
                height: '100%',
                overflow: "hidden",
            }}
        >
            {/* Video element */}
            <video
                ref={videoRef}
                src={src}
                style={{ width: '100%', height: '100%' }}
                muted
            />
            {/* Play button overlay */}
            {!isPlaying && (
                <div
                    onClick={handlePlayPause}
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "50px",
                        height: "50px",
                        background: "rgba(0, 0, 0, 0.6)",
                        borderRadius: "20px !important",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="white"
                        viewBox="0 0 24 24"
                    >
                        <path d="M8 5v14l11-7z" />
                    </svg>
                </div>
            )}
        </div>
    );
}

function Child_Our_Work() {

    let items = [

        { id: 2, link: 'https://georgiafilming.s3.us-east-1.amazonaws.com/ITTStar_Promo_Video.mp4' },
        { id: 3, link: "https://georgiafilming.s3.us-east-1.amazonaws.com/Brazilian+Wax+%26+Spa_May2024.mp4" },
        { id: 4, link: "https://georgiafilming.s3.us-east-1.amazonaws.com/VDI+Video+-+Youtube+.mp4" },
        { id: 5, link: "https://georgiafilming.s3.us-east-1.amazonaws.com/Final_LuxuryGoods_Video.mp4" },
        { id: 6, link: "https://georgiafilming.s3.us-east-1.amazonaws.com/CEO_+Video_New+Logo.mp4" },
        { id: 7, link: "https://georgiafilming.s3.us-east-1.amazonaws.com/New_3Min.mp4" },
        { id: 8, link: "https://georgiafilming.s3.us-east-1.amazonaws.com/GenAI_Promo+2.mp4" },
        { id: 9, link: "https://georgiafilming.s3.us-east-1.amazonaws.com/OpenSearch_Webinar_Promo.mp4" },
        // { id: 10, link: 'https://georgiafilming.s3.us-east-1.amazonaws.com/CEO_ Video_New Logo.mp4' },
        { id: 11, link: "https://georgiafilming.s3.us-east-1.amazonaws.com/Data+Analytics+Course.mp4" },
        { id: 12, link: "https://georgiafilming.s3.us-east-1.amazonaws.com/Whatsapp+wishes.mp4" },
        { id: 13, link: "https://georgiafilming.s3.us-east-1.amazonaws.com/Whatsapp+wishes_2.mp4" },
        { id: 14, link: "https://georgiafilming.s3.us-east-1.amazonaws.com/Fletronix_03-13-24.mp4" },
        { id: 16, link: "https://georgiafilming.s3.us-east-1.amazonaws.com/DRS_Promo 2_11-03-23_F.mp4" },
        { id: 17, link: "https://georgiafilming.s3.us-east-1.amazonaws.com/Final.mp4" },
        { id: 18, link: "https://georgiafilming.s3.us-east-1.amazonaws.com/For YT.mp4" },
        // { id: 19, link: "https://georgiafilming.s3.us-east-1.amazonaws.com/GenAI_Promo 2.mp4" },
        { id: 20, link: "https://georgiafilming.s3.us-east-1.amazonaws.com/GenAI_Webinar_P1.mp4" },
        { id: 21, link: "https://georgiafilming.s3.us-east-1.amazonaws.com/Nanda_Birthday_Video.mp4" },

    ]
    const allTab = [
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/3x6_Media_standee_USA.jpg',
            title: 'studio',
        },
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/15th_Aug_1.png',
            title: 'studio',
        },
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/1_Design.jpg',
            title: 'shotting',
        },
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/2_Design.jpg',
            title: 'production',
        },
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/certificate_1.jpg',
            title: 'shotting',
        },
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/Christmas 01_12-23-22.png',
            title: 'production',
        },
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/Christmas_12-23-22.png',
            title: 'studio',
        },
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/Design_02-03.jpg',
            title: 'studio',
        },
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/D2.jpg',
            title: 'studio',
        },
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/D4.jpg',
            title: 'shotting',
        },
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/Brouchers_Real Estate2.jpg',
            title: 'studio',
        },
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/D-4.png',
            title: 'production',
        },

        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/Design_01.jpg',
            title: 'studio',
        },
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/Design_03.jpg',
            title: 'studio',
        },
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/celestial-bow-sri-rama-navami-divine-dawn.jpg',
            title: 'studio',
        },
        
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/power-of-DevSecOps.jpg',
            title: 'production',
        },

        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/Immersion-8.png',
            title: 'production',
        },
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/Verifacto_8-7-23.jpg',
            title: 'studio',
        },
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/Security-at-the-speed-of-innovation.png',
            title: 'studio',
        },
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/Design_02 1.jpg',
            title: 'shotting',
        },
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/Design_02 2.jpg',
            title: 'production',
        },
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/Design_02-01.jpg',
            title: 'production',
        },
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/Design_02-02.jpg',
            title: 'studio',
        },
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/Empowering-you-to-make-data-driven.png',
            title: 'production',
        },
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/3.png',
            title: 'studio',
        },
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/Embrace-the-power-of-DevSecOps.png',
            title: 'Coffee table',
        },
        {
            img: 'https://georgiafilming.s3.us-east-1.amazonaws.com/Design Shankranti.jpg',
            title: 'studio',
        },
    ];

    return (
        <>
            <Box component='main' sx={{ marginBlock: '40px' }} >
                <Container sx={{ maxWidth: { lg: '1400px' } }}>
                    <Grid
                        container
                        direction='column'
                        justifyContent='center'
                        alignItems='center'
                        mb={5}
                    >
                        <Grid item xs='2'></Grid>
                        <Grid
                            item
                            xs='8'
                            style={{
                                textAlign: 'center',
                                fontSize: '24px',
                                lineHeight: '36px'
                            }}
                        >
                            We are delighted to provide you with a comprehensive briefing
                            on the works and accomplishments of Our Media House. As a
                            leading media organization, we pride ourselves on delivering
                            high-quality content, engaging storytelling, and effective
                            communication strategies to our clients.
                        </Grid>
                        <Grid item xs='2'></Grid>
                    </Grid>
                </Container>
            </Box>

            <Container sx={{ marginBottom: '70px' }}>
                <Grid container spacing={1} >
                    {
                        items.map((item) =>
                            <Grid item lg={4} md={4} sm={6} xs={12} gap={10}>
                                {
                                    item.link
                                        ?
                                        <VideoCard key={item.id} src={item.link} />
                                        :
                                        // <img src={item.img} height={'200px'} width={'300px'} />
                                        ""
                                }
                            </Grid>
                        )
                    }
                </Grid>

                <Grid container direction="column" justifyContent="center" marginTop={'40px'} alignItems="center">
                    <ImageList
                        variant="masonry"
                        cols={4}
                        gap={8}
                        sx={{ width: "100%", maxHeight: "none", overflowY: "visible" }}
                    >
                        {allTab.map((item) => (
                            <ImageListItem key={item.img}>
                                <img
                                    src={`${item.img}?w=248&fit=crop&auto=format`}
                                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.title}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Grid>

            </Container>



        </>
    )
}

function Our_Work() {
    return (
        <CommonContent
            mainData={<Child_Our_Work />}
            backgroundImage="https://georgiafilming.s3.us-east-1.amazonaws.com/10.png"
            imageContent="OUR WORK"
        />
    )
}

export default Our_Work