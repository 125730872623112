import React from 'react'
import CommonContent from '../CommonRouter/commonContent'
import { Card, CardContent, CardMedia, Container, createTheme, Grid, List, ListItem, Paper, ThemeProvider, Typography } from '@mui/material'
import styled from '@emotion/styled'
import './style.css'
import ContactForm from './contactform'
import DialogWithContactForm from './DialogWithContactForm'

const darkTheme = createTheme({
    palette: {
        primary: {
            // Purple and green play nicely together.
            main: '#C19B24',
            contrastText: '#ffffff'
        },
        secondary: {
            // This is green.A700 as hex.
            main: '#C19B24'
        }
    },
    typography: {
        subheading: {
            color: '#C19B24',
            font: 'normal normal normal 12px/20px Playfair Display !important'
        },
        avname: {
            font: 'normal normal 500 20px/34px Playfair Display !important'
        },
        number: {
            font: 'normal normal bold 36px/77px Josefin Sans !important',
            color: '#fff'
        },
        number_lbl: {
            font: 'normal normal 500 20px/74px Playfair Display',
            color: '#fff'
        },
        wsh: {
            color: 'black',
            font: 'normal normal bold 21px/45px Josefin Sans !important'
        },
        wsh1: {
            color: 'white',
            font: 'normal normal bold 21px/45px Josefin Sans !important'
        },
        wsp: {
            color: 'black',
            font: 'normal normal normal 16px/28px Playfair Display !important'
        },
        wsp1: {
            color: 'white',
            font: 'normal normal normal 16px/28px Playfair Display !important'
        },
        address: {
            font: 'normal normal normal 16px/32px Josefin Sans !important'
        }
    }
})

const Item = styled(Paper)(({ theme }) => ({
    // padding: theme.spacing(1),
    textAlign: 'center',
    background: 'none',
    boxShadow: 'none',
    // [theme.breakpoints.down('md')]: {
    //   backgroundColor: '#27292A'
    // }
}))
function Child_Our_services() {

    return (
        <>
            <ThemeProvider theme={darkTheme}>
                <Grid container>
                    <Grid
                        // ref={serviceRef}
                        container
                        spacing={1}
                        // sx={{
                        //     background: {
                        //         xs: '#27292A',
                        //         md: "url('images/bg-service.jpg') center center no-repeat"
                        //     }
                        // }}
                        style={{ color: 'black' }}
                    >
                        <Container sx={{ maxWidth: { lg: '1400px' } }}>
                            <Grid
                                container
                                direction='column'
                                justifyContent='center'
                                alignItems='center'
                                marginY={0}
                                paddingY={4}
                            >
                                {/* <Grid item xs={12}>
                             <Typography
                               variant='h2'
                               className='title2'
                               sx={{ mt: 4 }}
                               style={{ color: '#fff' }}
                             >
                               SERVICES <br />{' '}
                               <div style={{ textAlign: 'center', fontSize: '16px' }}>
                                 -----------------
                               </div>
                             </Typography>
                           </Grid> */}

                                <Grid
                                    item
                                    xs={12}
                                    style={{ display: 'flex', justifyContent: 'center' }}
                                >
                                    <Typography
                                        sx={{ my: 2 }}
                                        style={{
                                            fontSize: '22px',
                                            lineHeight: '30px',
                                            textAlign: 'center',
                                            // maxWidth: '70%'
                                        }}
                                        variant='p'
                                    >
                                        We understand that in today's digital age, having a robust
                                        online presence is crucial for any business to stay relevant
                                        and competitive. Therefore, our focus is on providing
                                        customised solutions that cater to the unique needs of each
                                        of our clients. We understand that every project is
                                        different, and we take the time to understand your goals,
                                        your audience, and your brand before we start working on
                                        your project.
                                    </Typography>
                                </Grid>

                                <Grid container py={5} spacing={1} >
                                    {/* <Grid item xs={12} sm={6} md={4}>
                                        <Item sx={{ m: 4 }}>
                                        <Card
                                                sx={{
                                                    width: 300,
                                                    height:300,
                                                    padding:'20px',
                                                    borderRadius: '8px',
                                                    boxShadow: 3,
                                                    '&:hover': {
                                                        boxShadow: 6,
                                                        transform: 'scale(1.05)',
                                                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                                    },
                                                }}
                                            >
                                            <img
                                                src='/images/multimedia.png'
                                                className='services-card-image'
                                            />
                                            <Typography variant='wsh' component='p'  >
                                                HIRE PRODUCTION
                                            </Typography>
                                            <Typography variant='wsp' component='p'>
                                                Corporate Videos, Ad-Films/Digital Films, Social Media
                                                Videos, Feature Films, Short Films, Music Videos, Video
                                                Interviews, Documentaries, Video Editing
                                            </Typography>
                                            </Card>
                                        </Item>

                                    </Grid> */}

                                    <Grid item xs={12} sm={6} md={4}>
                                        <Item sx={{}}>
                                            <Card
                                                sx={{
                                                    width: 300,
                                                    height: 450,
                                                    padding: '20px',
                                                    borderRadius: '8px',
                                                    backgroundColor: '#F1F1F1',
                                                    boxShadow: 3,
                                                    '&:hover': {
                                                        boxShadow: 6,
                                                        transform: 'scale(1.05)',
                                                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                                    },
                                                }}
                                            >
                                                <img
                                                    style={{ color: "white" }}
                                                    src='/images/film.png'
                                                    className='services-card-image'
                                                />
                                                <Typography variant='wsh' component='p'>
                                                    ANIMATION & XR / VR
                                                </Typography>
                                                <Typography variant='wsp' component='div' >
                                                    <List >
                                                        <ListItem >2D and 3D animation </ListItem>
                                                        <ListItem>Motion graphics</ListItem>
                                                        <ListItem>Stop motion videos</ListItem>
                                                        <ListItem>Whiteboard videos</ListItem>
                                                        <ListItem>Extended Reality (XR) Services & Virtual Production</ListItem>
                                                    </List>
                                                </Typography>
                                                <div style={{marginTop:'15px'}}>
                                                    <DialogWithContactForm PopupContent='Get info' color='black'
                                                    textColor={'white'} />
                                                </div>

                                            </Card>
                                        </Item>
                                    </Grid>



                                    <Grid item xs={12} sm={6} md={4}>
                                        <Item sx={{ }}>
                                            <Card
                                                sx={{
                                                    width: 300,
                                                    height: 450,
                                                    padding: '20px',
                                                    backgroundColor: '#F1F1F1',
                                                    borderRadius: '8px',
                                                    boxShadow: 3,
                                                    '&:hover': {
                                                        boxShadow: 6,
                                                        transform: 'scale(1.05)',
                                                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                                    },
                                                }}
                                            >
                                                <img
                                                    src='/images/ui-video-play.png'
                                                    className='services-card-image'
                                                />
                                                <Typography variant='wsh' component='p'>
                                                    BRANDING STRATEGY
                                                </Typography>
                                                {/* <Typography variant='wsp' component='p'>
                                                    Logo design, Brand messaging, Brand positioning, Brand
                                                    voice, Style guide, Social Media Branding.
                                                </Typography> */}
                                                <Typography variant='wsp' component='div'>
                                                    <List>
                                                        <ListItem>Logo design</ListItem>
                                                        <ListItem>Brand messaging</ListItem>
                                                        <ListItem>Brand positioning</ListItem>
                                                        <ListItem>Brand voice</ListItem>
                                                        <ListItem>Style guide</ListItem>
                                                        <ListItem>Social Media Branding</ListItem>
                                                    </List>
                                                </Typography>
                                                <div>
                                                    <DialogWithContactForm PopupContent='Get info' 
                                                        color='black'
                                                        textColor={'white'} />
                                                </div>
                                            </Card>
                                        </Item>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <Item sx={{  }}>
                                            <Card
                                                sx={{
                                                    width: 300,
                                                    height: 450,
                                                    padding: '20px',
                                                    borderRadius: '8px',
                                                    backgroundColor: "#F1F1F1",
                                                    boxShadow: 3,
                                                    '&:hover': {
                                                        boxShadow: 6,
                                                        transform: 'scale(1.05)',
                                                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                                    },
                                                }}
                                            >
                                                <img
                                                    src='/images/video-cam.png'
                                                    className='services-card-image'
                                                />
                                                <Typography variant='wsh' component='p'>
                                                    POST PRODUCTION
                                                </Typography>
                                                {/* <Typography variant='wsp' component='p'>
                                                    Video Editing, Sound Editing, Graphic Designing, Voice
                                                    overs, Color Grading, Content moderation.
                                                </Typography> */}
                                                <Typography variant='wsp' component='div'>
                                                    <List>
                                                        <ListItem>Video Editing</ListItem>
                                                        <ListItem>Sound Editing</ListItem>
                                                        <ListItem>Graphic Designing</ListItem>
                                                        <ListItem> Voice overs</ListItem>
                                                        <ListItem>Color Grading</ListItem>
                                                        <ListItem>Content moderation</ListItem>
                                                    </List>
                                                </Typography>
                                                <div>
                                                    <DialogWithContactForm PopupContent='Get info' color='black'
                                                        textColor={'white'}
                                                    />
                                                </div>
                                            </Card>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                </Grid>
                {/* <DialogWithContactForm PopupContent="Get pricing"  /> */}
            </ThemeProvider>
        </>
    )
}


function Our_services() {
    const backgroundImage = "https://georgiafilming.s3.us-east-1.amazonaws.com/8.png"

    return (
        <>
            <CommonContent mainData={<Child_Our_services />}
                backgroundImage={backgroundImage}
                imageContent="OUR SERVICES"
            />

        </>
    )
}
export default Our_services