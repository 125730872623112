import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

const darkTheme = createTheme({
  palette: {
    CardActions: {
      blogheading: {
        color: '#000 !important',
        font: 'font: normal normal bold 24px/50px Josefin Sans !important',
      },
    }
  }
});
const HeadTypography = styled(Typography)({
  color: '#000 !important',
  fontSize: '20px',
  fontWeight: 'bold',
  fontFamily: 'Josefin sans !important',
    color: '#000!important',
    textAlign: 'center'
});
const ParaTypography = styled(Typography)({
  fontFamily: 'Playfair Display !important',
  color: '#000 !important',
  fontSize: '16px',
  textAlign: 'center'
});
const CaerdButton = styled(Button)({
  fontFamily: 'Playfair Display !important',
  color: '#000 !important',
 
  textAlign: 'center',
  fontWeight: 'bold',
});
const blogs = [
  { 
    id: 1, 
    date: "17 April 2022", 
    title: "Get Ready To Make Awesome", 
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore", 
    image: "images/blog1.png",
  },
  { 
    id: 2, 
    date: "18 April 2022", 
    title: "The Best Design Film", 
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore", 
    image: "images/blog2.png",
  },
  { 
    id: 3, 
    date: "19 April 2022", 
    title: "The Best Design Film",  
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore", 
    image: "images/blog3.png"
  },
];
export default function OurBlog() {
  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ flexGrow: 1 }}>
      <Grid container  direction="row"
  justifyContent="center"
  alignItems="center" spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
      {blogs.map((item) => (    
        <Grid item xs={4} sm={4} md={4} key={item.id}>                     
        <Card sx={{ maxWidth: 418 }}  >
            <Grid container  direction="column"
            justifyContent="center"
            alignItems="center" >
          <CardMedia 
            component="img"
            height="327"
            image={item.image}
            alt="green iguana"
          />
          <CardContent sx={{ paddingBottom: 0 }}>
          <Typography sx={{ fontSize: 14,textAlign: 'center', color:'#a29191'}}  gutterBottom>
          {item.date}
          </Typography>
            
            <HeadTypography  variant="subheading" component="div" sx={{ my: 2 }} >
            {item.title}
            </HeadTypography>
            <ParaTypography variant="body2" >
            {item.desc}
            </ParaTypography>
          </CardContent>
          <CardActions sx={{ my: 2 }}>
            <CaerdButton size="small">Read More</CaerdButton>
          </CardActions>
          </Grid>
        </Card>
        </Grid>
        ))}
      </Grid>
    </Box>


    </ThemeProvider>
  );
}