import React, { useEffect, useState } from 'react'
import MainHeader from './header'
import { Box, Card, Container, Grid, Typography } from '@mui/material'
import Footer from './footer'
import { useLocation } from 'react-router'

function CommonContent(props) {
    const location = useLocation()
    const { backgroundImage, mainData, imageContent } = props
    const background = backgroundImage
    const [url, setUrl] = useState()

    useEffect(() => {
        let ur = location.pathname
        setUrl(ur)
    }, [])
    return (
        <>
            <MainHeader />
            <Grid
                container
                py={5}
                sx={{
                    width: '100%',
                    height: '100%',
                    background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${background}')`,
                    backgroundSize: "cover",
                    backgroundPosition:
                        (url == "/" || url == "/our-work")
                            ?
                            { lg: 'center', md: 'center', sm: 'right', xs: 'right' }
                            : "center"
                    ,
                    backgroundRepeat: "no-repeat",
                }}
                style={{ color: '#fff' }}
            >
                <Container >
                    <Grid
                        container
                        direction='column'
                        justifyContent='center'
                        alignItems='center'
                        my={8}
                    >
                        <Grid item xs={12}>
                            <Typography variant='h2' className='title2' sx={{ mt: 16,lineHeight:'44px !important' }}>
                                {imageContent}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Grid >
            <Box style={{ marginTop: '-1px' }}>
                {mainData}
            </Box>
            <Footer />
        </>
    )
}

export default CommonContent