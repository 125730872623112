import styled from '@emotion/styled'
import { Container, createTheme, Grid, List, ListItem, Paper, TextField, ThemeProvider, Typography } from '@mui/material'
import React, { useRef } from 'react'
import PinDropIcon from '@mui/icons-material/PinDrop'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import TwitterIcon from '@mui/icons-material/Twitter'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import emailjs from '@emailjs/browser'
import Button from '@mui/material/Button'
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Link } from 'react-router'
import '../components/style.css'

function Footer() {
    const form = useRef()

    const sendEmail = e => {
        e.preventDefault()

        emailjs
            .sendForm(
                'service_lcikxhd',
                'template_ra4yf1l',
                form.current,
                'hHk1gJukMYaFgCsxP'
            )
            .then(
                result => {
                    console.log(result.text)
                },
                error => {
                    console.log(error.text)
                }
            )
        e.target.reset()
    }

    const darkTheme = createTheme({
        palette: {
            primary: {
                // Purple and green play nicely together.
                main: '#C19B24',
                contrastText: '#ffffff'
            },
            secondary: {
                // This is green.A700 as hex.
                main: '#C19B24'
            }
        },
        typography: {
            subheading: {
                color: '#C19B24',
                font: 'normal normal normal 12px/20px Playfair Display !important'
            },
            avname: {
                font: 'normal normal 500 20px/34px Playfair Display !important'
            },
            number: {
                font: 'normal normal bold 36px/77px Josefin Sans !important',
                color: '#fff'
            },
            number_lbl: {
                font: 'normal normal 500 20px/74px Playfair Display',
                color: '#fff'
            },
            wsh: {
                color: '#fff',
                font: 'normal normal bold 21px/45px Josefin Sans !important'
            },
            wsp: {
                color: '#fff',
                font: 'normal normal normal 16px/28px Playfair Display !important'
            },
            address: {
                font: 'normal normal normal 16px/32px Josefin Sans !important'
            }
        }
    })

    const Item = styled(Paper)(({ theme }) => ({
        padding: theme.spacing(1),
        textAlign: 'center',
        background: 'none',
        boxShadow: 'none',
        [theme.breakpoints.down('md')]: {
            backgroundColor: '#27292A'
        }
    }))
    return (
        <>
            <ThemeProvider theme={darkTheme}>

                <Grid
                    // ref={contactRef}
                    container
                    spacing={1}
                    sx={{
                        background: {
                            xs: '#27292A'
                        }
                    }}
                    style={{ color: '#fff' }}
                >
                    <Container sx={{ maxWidth: { lg: '1400px' } }}>
                        <Grid container spacing={1} marginBottom={2} justifyContent={'space-between'} alignItems={'center'}>
                            <Grid item xs={12} sm={6} md={4} >
                                <Item
                                    sx={{ m: 4 }}
                                    style={{ color: '#fff', textAlign: 'left' }}
                                >

                                    <Typography
                                        variant='wsh'
                                        component='p'
                                        style={{ textAlign: 'left', marginBottom: '10px' }}
                                    >
                                        GET IN TOUCH WITH US
                                    </Typography>

                                    <Typography
                                        component='p'
                                        style={{
                                            marginBottom: '5px'
                                        }}
                                    >
                                        If you have any questions, please drop a email with
                                        relevant information, will get back to you. Thank you!
                                    </Typography>

                                    <br />
                                    <Typography
                                        component='p'
                                        style={{
                                            // font: 'normal normal normal 14px/26px Playfair Display',
                                            marginBottom: '5px'
                                        }}
                                    >
                                        We make videos that connect people with companies.
                                    </Typography>
                                    <hr></hr>
                                    <br />
                                    <a
                                        href='tel:+16785610074'
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Typography
                                            variant='address'
                                            component='p'
                                            style={
                                                {
                                                    // font: 'normal normal normal 14px/26px Playfair Display'
                                                }
                                            }
                                        >
                                            <PhoneInTalkIcon
                                                style={{
                                                    float: 'left',
                                                    marginRight: '8px',
                                                    marginTop: '8px',
                                                    fontSize: '20px',
                                                    textDecoration: 'none',
                                                    color: '#FFF'
                                                }}
                                            /><span style={{ fontSize: '18px',color:'white' }}>: +1 (678) 615-6384</span>
                                            <span
                                                style={{
                                                    fontSize: '23px',
                                                    textDecoration: 'none',
                                                    color: '#FFF'
                                                    // font: 'normal normal normal 14px/26px Playfair Display'
                                                }}
                                            >
                                                
                                            </span>
                                        </Typography>
                                    </a>
                                    <Typography
                                        variant='address'
                                        component='p'
                                        style={
                                            {
                                                // font: 'normal normal normal 14px/26px Playfair Display'
                                            }
                                        }
                                    >
                                        <MailOutlineIcon
                                            style={{ float: 'left', marginRight: '8px', marginTop: '4px' }}
                                        /> <span style={{ fontSize: '18px' }}>: info@georgiafilming.com</span>
                                        {/* <span
                                            style={{
                                                // font: 'normal normal normal 14px/26px Playfair Display'
                                                fontSize: '20px'
                                            }}
                                        >

                                        </span> */}
                                    </Typography>
                                </Item>
                            </Grid>
                          
                            <Grid item xs={12} sm={6} md={4} justifyItems={'center'} >
                                <Item
                                    sx={{ m: 4 }}
                                    style={{ color: '#fff', textAlign: 'left' }}
                                >
                                    <Typography variant='wsh'
                                        component='p'>Quick Links</Typography>
                                    <Typography variant="wsp" component="div">
                                        <List sx={{ display: 'inline-block', justifyContent: 'center', alignItems: 'center' }}>
                                            <Link to={'/'} style={{ textDecoration: 'none' }}>
                                                <p style={{ color: 'white', transition: 'color 0.3s ease, text-decoration 0.3s ease' }} className="hover-link">Home</p>
                                            </Link>
                                            <Link to={'/about-us'} style={{ textDecoration: 'none' }}>
                                                <p style={{ color: 'white', transition: 'color 0.3s ease, text-decoration 0.3s ease' }} className="hover-link">About us</p>
                                            </Link>
                                            <Link to={'/our-services'} style={{ textDecoration: 'none' }}>
                                                <p style={{ color: 'white', transition: 'color 0.3s ease, text-decoration 0.3s ease' }} className="hover-link">Our Services</p>
                                            </Link>
                                            <Link to={'/our-work'} style={{ textDecoration: 'none' }}>
                                                <p style={{ color: 'white', transition: 'color 0.3s ease, text-decoration 0.3s ease' }} className="hover-link">Our Work</p>
                                            </Link>
                                            <Link to={'/contact-us'} style={{ textDecoration: 'none' }}>
                                                <p style={{ color: 'white', transition: 'color 0.3s ease, text-decoration 0.3s ease' }} className="hover-link">Contact us</p>
                                            </Link>
                                            <Link to={'/explainer-video'} style={{ textDecoration: 'none' }}>
                                                <p style={{ color: 'white', transition: 'color 0.3s ease, text-decoration 0.3s ease' }} className="hover-link">Explainer Video</p>
                                            </Link>
                                            <Link to={'/animation-services'} style={{ textDecoration: 'none' }}>
                                                <p style={{ color: 'white', transition: 'color 0.3s ease, text-decoration 0.3s ease' }} className="hover-link">Animation Services</p>
                                            </Link>
                                            <Link to={'/graphic-designing'} style={{ textDecoration: 'none' }}>
                                                <p style={{ color: 'white', transition: 'color 0.3s ease, text-decoration 0.3s ease' }} className="hover-link">Graphic designing</p>
                                            </Link>
                                        </List>



                                    </Typography>
                                </Item>
                            </Grid>


                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                style={{ color: '#fff', textAlign: 'left' }}
                            >
                                <Item
                                    // sx={{ m: 4 }}
                                    style={{ color: '#fff', textAlign: 'left' }}
                                >
                                    <img
                                        src='https://georgiafilming.s3.us-east-1.amazonaws.com/Worldmap.png'
                                        className='cjkh'
                                        style={{ width: '400px', height: '280px' }}
                                    />
                                    <Typography
                                        variant='address'
                                        component='p'
                                        style={{
                                            font: 'normal normal normal 14px/26px Playfair Display',
                                            marginBottom: '5px',
                                            textAlign: 'center'
                                        }}
                                    >
                                        <PinDropIcon
                                            style={{ float: 'left', marginRight: '6px',marginTop:'8px' }}
                                        />
                                        <span
                                        >
                                            11175 Cicero Drive, Suite 100, Alpharetta, GA 30022, USA,
                                        </span><br />

                                    </Typography>
                                    <p style={{ marginInline: '20px' }}> <span style={{ fontWeight: 'bold' }}> Delivery Center:</span> Bengaluru, India</p>
                                </Item>
                            </Grid>
                        </Grid>

                        <hr sx={{ m: 4 }} />

                        <Grid container sx={{ my: 1 }} spacing={1}>
                            <Grid item xs={12} sm={6} md={6}>
                                <Item style={{ color: '#fff', textAlign: 'left' }}>
                                    Copyright ©2025 Georgia Filming. All Rights Reserved.
                                </Item>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Item
                                    style={{
                                        color: '#fff',
                                        display: 'inline-flex',
                                        float: 'right'
                                    }}
                                    sx={{
                                        textAlign: 'right',
                                        textAlign: { xs: 'center', md: 'right' }
                                    }}
                                >
                                    {' '}
                                    <p>Powered By NGR Group</p> &emsp;
                                    <a style={{ color: 'white' }} href='https://www.facebook.com/ITTStarConsulting'><FacebookIcon /></a> <a style={{ color: 'white' }} href='https://www.youtube.com/@NGRProductionsMediaHouse'><YouTubeIcon /></a> <a style={{ color: 'white' }} href='https://www.instagram.com/ittstarconsulting/#'><InstagramIcon /></a>
                                    <a style={{ color: 'white' }} href='https://www.linkedin.com/company/ittstar-consulting-llc/'><LinkedInIcon /></a>
                                </Item>
                            </Grid>
                        </Grid>
                    </Container >
                </Grid >
            </ThemeProvider >
        </>
    )
}

export default Footer