import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import ContactForm from './contactform';

const DialogWithContactForm = ({PopupContent,color,textColor}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
        
      <Button variant="contained" style={{backgroundColor:`${color}`,color:`${textColor}`}} onClick={handleClickOpen}>
        {PopupContent}
      </Button>
      
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
      
        <DialogTitle sx={{fontSize:'30px'}}>Get info & pricing</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <span style={{color:'red',fontSize:'20px',fontWeight:'bold'}}>X</span>
          </Button>
        </DialogActions>
      </div>
        <DialogContent>
            <ContactForm />
        </DialogContent>
        
      </Dialog>
    </div>
  );
};

export default DialogWithContactForm;
