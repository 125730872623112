import * as React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Grid from '@mui/material/Grid';

const allTab = [
  {
    img: '/images/gal2.png',
    title: 'shotting',
  },
  {
    img: '/images/gal1.png',
    title: 'production',
  },
  {
    img: '/images/gal3.png',
    title: 'studio',
  },
  {
    img: '/images/gal4.png',
    title: 'shotting',
  },
  {
    img: '/images/gal5.png',
    title: 'production',
  },
  {
    img: '/images/gal6.png',
    title: 'studio',
  },
  {
    img: '/images/gal7.png',
    title: 'studio',
  },
  {
    img: '/images/gal8.png',
    title: 'shotting',
  },
  {
    img: '/images/gal9.png',
    title: 'production',
  },
  {
    img: '/images/gal10.png',
    title: 'studio',
  },
  {
    img: '/images/gal11.png',
    title: 'studio',
  },

];
const locationTab = [
  {
    img: '/images/gal2.png',
    title: 'shotting',
  },
  {
    img: '/images/gal1.png',
    title: 'production',
  },

  {
    img: '/images/gal5.png',
    title: 'production',
  },
  {
    img: '/images/gal6.png',
    title: 'studio',
  },
  {
    img: '/images/gal7.png',
    title: 'studio',
  },
  {
    img: 'https://images.unsplash.com/photo-1519710164239-da123dc03ef4',
    title: 'Coffee table',
  },
];
const productionTab = [

  {
    img: '/images/gal5.png',
    title: 'production',
  },
  {
    img: '/images/gal6.png',
    title: 'studio',
  },
  {
    img: '/images/gal7.png',
    title: 'studio',
  },
];
const shottingTab = [

  {
    img: '/images/gal1.png',
    title: 'production',
  },

  {
    img: '/images/gal5.png',
    title: 'production',
  },
  {
    img: '/images/gal6.png',
    title: 'studio',
  },
];
const studioTab = [

  {
    img: '/images/gal6.png',
    title: 'studio',
  },
  {
    img: '/images/gal7.png',
    title: 'studio',
  },
];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function GalleryTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box container direction="column"
      justifyContent="center"
      alignItems="center" style={{ position: 'relative', margin: '0 auto', overflowX: "auto !important", }} >

      <Grid container direction="column" justifyContent="center" alignItems="center">
        <ImageList
          variant="masonry"
          cols={4}
          gap={8}
          sx={{ width: "100%", maxHeight: "none", overflowY: "visible" }}
        >
          {allTab.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Grid>
      <p
        style={{
          font: ' "Josefin Sans", sans-serif',
          fontSize: "24px",
          textAlign: "center",
          fontWeight: "",
          marginTop:'10px'
        }}
      >
        Film. Entertainment. Arts. Music. Live Events. Talent. Corporate. Fashion. Lifestyle.
      </p>
    </Box>
  );
}