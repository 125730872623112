import { AppBar, Box, Button, Card, Collapse, Container, Divider, Drawer, List, ListItem, ListItemButton, styled, Toolbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Form1 from '../components/form1'
import Form2 from '../components/form2'
import AboutPopup from '../components/aboutPopUp'
import GalleryPopup from '../components/galleryPopup'
import ServicesPopup from '../components/servicesPopup'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { Link, useLocation, useNavigate, useParams } from 'react-router'
import DialogWithContactForm from '../components/DialogWithContactForm'
import HomeIcon from '@mui/icons-material/Home';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ExpandLess, ExpandMore } from '@mui/icons-material'

const StyledToolbar = styled(Toolbar)({
    backgroundColor: '#27292A', //#307192
    color: '#fff'
})

export default function MainHeader() {

    const navigate = useNavigate()
    const [open1, setOpen1] = React.useState(false)
    const [openAbout, setOpenAbout] = React.useState(false)
    const [openGallery, setOpenGallery] = React.useState(false)
    const [openServices, setOpenServices] = React.useState(false)
    const [open2, setOpen2] = React.useState(false)
    const [headR, setHeadR] = React.useState(false)
    const [tabv, setTabv] = React.useState("ho")

    const location = useLocation();

    useEffect(() => {
        let endUrl = location.pathname
        if (endUrl == "/about") {
            setTabv("ab")
        }
        else if (endUrl == "/our-services" || endUrl == "/explainer-video" || endUrl == "/animation-services" || endUrl == "/graphic-designing") {
            setTabv("se")
        }
        else if (endUrl == "/our-services") {
            setTabv("se")
        }
        else if (endUrl == "/explainer-video") {
            setTabv("se")
        }
        else if (endUrl == "/our-services") {
            setTabv("se")
        }
        else if (endUrl == "/contact-us") {
            setTabv("co")
        }
        else if (endUrl == "/our-work") {
            setTabv("ow")
        }
    }, [location])

    const handleClickOpen1 = () => {
        setOpen1(true)
    }

    React.useEffect(() => {
        const onScroll = e => {
            if (document.body.scrollTop > 100) {
                setHeadR(true)
            } else {
                setHeadR(false)
            }
        }
        window.addEventListener('scroll', onScroll, true)
        return () => window.removeEventListener('scroll', onScroll, true)
    }, [])


    const handleClose1 = () => {
        setOpen1(false)
    }

    const handleClickOpen2 = () => {
        setOpen2(true)
    }

    const handleClickOpenAbout = () => {
        setOpenAbout(true)
    }
    const handleClickOpenGallery = () => {
        setOpenGallery(true)
    }
    const handleCloseGallery = () => {
        setOpenGallery(false)
    }

    const handleClickOpenServices = () => {
        setOpenServices(true)
    }
    const handleCloseServices = () => {
        setOpenServices(false)
    }

    const handleCloseAbout = () => {
        setOpenAbout(false)
    }

    const handleClose2 = () => {
        setOpen2(false)
    }
    const [isHovered, setIsHovered] = useState(false);
    const [hoveredLink, setHoveredLink] = useState(null);
    const [servicesOpen, setServicesOpen] = useState(false); // State for toggling sub-menu


    const handleServicesClick = () => {
        navigate("/our-services")
        setServicesOpen(!servicesOpen); // Toggle sub-menu
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
        console.log("Hovered over button");
        // Call your desired function here
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        console.log("Hover left");
        // Any cleanup or additional actions can be performed here
    };
    const [data, setData] = useState(false)

    const handleEnter = (val) => {
        setHoveredLink(val)
    }

    const toggleDrawer = () => {
        setData(!data);
    }


    return (
        <AppBar
            className={headR ? 'navActive' : 'navNot'}
            component='nav'
            position='static'
        >
            <Form1
            //   open1={open1}
            //   handleClickOpen1={handleClickOpen1}
            //   handleClose1={handleClose1}
            />
            <Form2
            //   open2={open2}
            //   handleClickOpen2={handleClickOpen2}
            //   handleClose2={handleClose2}
            />
            <AboutPopup
            //   openAbout={openAbout}
            //   handleClickOpenAbout={handleClickOpenAbout}
            //   handleCloseAbout={handleCloseAbout}
            />
            <GalleryPopup
            //   openGallery={openGallery}
            //   handleClickOpenGallery={handleClickOpenGallery}
            //   handleCloseGallery={handleCloseGallery}
            />
            <ServicesPopup
            //   openServices={openServices}
            //   handleClickOpenServices={handleClickOpenServices}
            //   handleCloseServices={handleCloseServices}
            />
            <Container sx={{ maxWidth: { lg: "1400px" }, position: { sm: "relative" } }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        py: 2,
                    }}
                >
                    {/* Logo */}
                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{
                                display: { xs: "block", sm: "block", marginRight: '20px' },
                            }}
                        >
                            <img style={{ width: "60px" }} src="https://georgiafilming.s3.us-east-1.amazonaws.com/logo.png" alt="Logo" />
                        </Typography>

                        {/* Hamburger Menu (Mobile Only) */}
                        <IconButton
                            color="inherit"
                            edge="start"
                            sx={{ display: { xs: "flex", md: "none" } }}
                            onClick={toggleDrawer}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>

                    {/* Desktop Menu */}
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                            justifyContent: "",
                        }}
                    >
                        <Button
                            onClick={() => navigate("/")}
                            className="menuItem"
                            sx={{
                                mx: 1,
                                color: "#fff",
                                fontSize: "20px",
                                textTransform: "capitalize",
                                borderBottom: tabv === "ho" ? "1px solid #fff" : "",
                            }}
                        >
                            <HomeIcon /> Home
                        </Button>
                        <Button
                            onClick={() => navigate("/about")}
                            className="menuItem"
                            sx={{
                                mx: 1,
                                color: "#fff",
                                fontSize: "20px",
                                textTransform: "capitalize",
                                borderBottom: tabv === "ab" ? "1px solid #fff" : "",
                            }}
                        >
                            About Us
                        </Button>
                        <Box
                            sx={{ position: "relative" }}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <Button
                                onClick={() => navigate("/our-services")}
                                className="menuItem"
                                sx={{
                                    mx: 1,
                                    color: "#fff",
                                    fontSize: "20px",
                                    textTransform: "capitalize",
                                    borderBottom: tabv === "se" ? "1px solid #fff" : "",
                                }}
                            >
                                Services <ArrowDropDownIcon />
                            </Button>
                            {isHovered && (
                                <Card
                                    sx={{
                                        position: "absolute",
                                        top: "40px",
                                        left: 0,
                                        width: "200px",
                                        zIndex: 10,
                                        borderRadius: "10px",
                                        backgroundColor: "white",
                                        padding: "10px",
                                    }}
                                >
                                    <Box>
                                        <Link
                                            to="/explainer-video"
                                            style={{ textDecoration: "none" }}
                                            onMouseEnter={() => handleEnter(1)}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: "14px",
                                                    color: "black",
                                                    padding: "5px",
                                                    backgroundColor: hoveredLink === 1 ? "#DAE2F9" : "transparent",
                                                    borderRadius: "4px",
                                                }}
                                            >
                                                Explainer Video
                                            </Typography>
                                        </Link>
                                        <Link
                                            to="/animation-services"
                                            style={{ textDecoration: "none" }}
                                            onMouseEnter={() => handleEnter(2)}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: "14px",
                                                    color: "black",
                                                    padding: "5px",
                                                    backgroundColor: hoveredLink === 2 ? "#DAE2F9" : "transparent",
                                                    borderRadius: "4px",
                                                    mt: 1,
                                                }}
                                            >
                                                Animation Services
                                            </Typography>
                                        </Link>
                                        <Link
                                            to="/graphic-designing"
                                            style={{ textDecoration: "none" }}
                                            onMouseEnter={() => handleEnter(3)}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: "14px",
                                                    color: "black",
                                                    padding: "5px",
                                                    backgroundColor: hoveredLink === 3 ? "#DAE2F9" : "transparent",
                                                    borderRadius: "4px",
                                                    mt: 1,
                                                }}
                                            >
                                                Graphic Designing
                                            </Typography>
                                        </Link>
                                    </Box>
                                </Card>
                            )}
                        </Box>
                        <Button
                            onClick={() => navigate("/our-work")}
                            className="menuItem"
                            sx={{
                                mx: 1,
                                color: "#fff",
                                fontSize: "20px",
                                textTransform: "capitalize",
                                borderBottom: tabv === "ow" ? "1px solid #fff" : "",
                            }}
                        >
                            Work
                        </Button>
                        <Button
                            onClick={() => navigate("/contact-us")}
                            className="menuItem"
                            sx={{
                                mx: 1,
                                color: "#fff",
                                fontSize: "20px",
                                textTransform: "capitalize",
                                borderBottom: tabv === "co" ? "1px solid #fff" : "",
                            }}
                        >
                            Contact Us
                        </Button>
                    </Box>
                    <DialogWithContactForm PopupContent={"Get Info & Pricing"} color='white'
                        textColor={'black'} />

                    {/* Mobile Drawer */}
                    <Drawer
                        anchor="left"
                        open={data}
                        onClose={toggleDrawer}
                        sx={{
                            display: { xs: "block", md: "none" },
                            "& .MuiDrawer-paper": { width: 250, backgroundColor: "#27292A", color: "#fff" },
                        }}
                    >
                        <Typography variant="h6" sx={{ textAlign: "center", p: 2 }}>
                            Menu
                        </Typography>
                        <Divider />
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => navigate("/")}>
                                    <HomeIcon sx={{ mr: 1 }} /> Home
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => navigate("/about")}>About Us</ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                {/* Services Button */}
                                <ListItemButton onClick={handleServicesClick}>
                                    Services {servicesOpen ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                            </ListItem>
                            {/* Sub-menu for Services */}
                            <Collapse in={servicesOpen} timeout="auto" unmountOnExit>
                                <List disablePadding sx={{ pl: 4 }}>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate("/explainer-video")}>
                                            Explainer Video
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate("/animation-services")}>
                                            Animation Services
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate("/graphic-designing")}>
                                            Graphic Designing
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => navigate("/our-work")}>Work</ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => navigate("/contact-us")}>Contact Us</ListItemButton>
                            </ListItem>
                        </List>
                    </Drawer>
                </Box>
            </Container>
        </AppBar >
    )
}
