import React, { useEffect } from 'react'
import CommonContent from '../CommonRouter/commonContent'
import ContactForm from '../components/contactform'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container, Typography } from '@mui/material';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));
const ChildContactUs = () => {

    
    return (
        <>
            <Box sx={{ flexGrow: 1, backgroundColor: '#f5f5f8' }}>
                <Container>
                    <Grid container spacing={2} sx={{ paddingY: "20px" }} justifyContent='space-evenly' >
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Typography variant='h5' className='title2'>Want videos that make a difference?</Typography>
                            <Typography  sx={{ my: 2 }}
                                style={{
                                    fontSize: '18px',
                                    color: '#000'
                                }}>Contact us now for a swift response.</Typography>
                            <ContactForm />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} justifyContent={'center'} justifyItems={'end'} alignContent={'center'} style={{backgroundColor:''}}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                               <img height={"350px"} style={{borderRadius:"10px"}} width={'450px'} src='https://georgiafilming.s3.us-east-1.amazonaws.com/concept-contact-us-customer-support-600nw-2505308177.webp'/>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Container>
                    <Grid container spacing={2} sx={{ paddingTop: "20px", paddingBottom: '80px', }} justifyContent='space-evenly' >
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <div style={{ textAlign: 'center' }}>
                                <Typography sx={{ fontSize: '25px' }}>Head Office</Typography>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2552.458927855479!2d-84.28088312556707!3d34.05120441778734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5756506bc9a33%3A0x26c137e884f71fc5!2s11175%20Cicero%20Dr%20STE%20100%2C%20Alpharetta%2C%20GA%2030022%2C%20USA!5e1!3m2!1sen!2sin!4v1737032099665!5m2!1sen!2sin" width="400" height="300" style={{ border: '0', borderRadius: '20px', marginTop: "20px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <div style={{ textAlign: 'center' }}>

                                <Typography sx={{ fontSize: '25px' }}>Delivery Center</Typography>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001.0558764629354!2d77.59825377367308!3d13.054992413047296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae10d5aaaaaaab%3A0x3d3c2dfe5e78bc2b!2sITTStar%20Global%20Services%20Pvt.%20Ltd.%20-%20Delivery%20Centre!5e1!3m2!1sen!2sin!4v1737115567280!5m2!1sen!2sin" width="400" height="300" style={{ border: '0', borderRadius: '20px', marginTop: "20px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

function Contact_Us() {
    const backgroundImage = "https://georgiafilming.s3.us-east-1.amazonaws.com/4.png"
    return (
        <>
            <CommonContent mainData={<ChildContactUs />}
                backgroundImage={backgroundImage}
                imageContent="CONTACT US"
            />

        </>
    )
}

export default Contact_Us